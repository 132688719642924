import styled from 'styled-components'

export const Button = styled.div<{ large?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${({ large }) => (large ? `31ch` : '215px')};
    min-width: 215px;
    height: 26px;
    font-weight: 500;
    font-size: 14px;
    opacity: 1;
    text-align: center;
    color: rgba(153, 153, 153, 1);
    background: rgba(255, 255, 255, 1);
    border-radius: 4px;
    box-shadow: 0px 2px 10px rgb(0 0 0 / 25%);
    overflow: hidden;
    cursor: pointer;
`
