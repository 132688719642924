import React, { useContext, useEffect, useState } from 'react'
import GoogleMapReact, { MapOptions, Position } from 'google-map-react'

import Marker from './Map/Marker'
import { IGeolocation, IPlace } from '../intefaces/map'
// @ts-ignore
import supercluster from 'points-cluster'
import Sidebar from './SideBar'
import { fetchDevices } from '../store/action'
import Grid from './Grid'
import { AccessTokenCtx } from './Context'
import { ILastMeasure } from '../intefaces/measure'
import moment from 'moment'
import AtmoIndices from './Atmo/AtmoIndices'

interface IPlaceOrCluster {
    numPoints: number
    points: IGeolocation[]
    wx: number
    wy: number
    x: number
    y: number
    zoom: number
}

const SimpleMap = ({
    language,
    groups,
}: {
    language: string
    groups: string[]
}) => {
    const accessToken = useContext(AccessTokenCtx)
    const [globalBounds, setGlobalBounds] = useState<any>()
    const [map, setMap] = useState<any>()
    const [maps, setMaps] = useState<any>()
    const [zoom, setZoom] = useState<number>(1)
    const [places, setPlaces] = useState<IGeolocation[]>([])
    const [placesAndCluster, setPlacesAndCluster] = useState<IPlaceOrCluster[]>(
        []
    )
    const [cluster, setCluster] = useState<Function>()
    const [gridData, setGridData] = useState<ILastMeasure[]>([])
    const [hoverId, setHoverId] = useState<string>()
    const [selectedSensor, setSelectedSensor] = useState<IGeolocation>(
        {} as IGeolocation
    )
    useEffect(() => {
        try {
            fetch('/api/v3/devices/measures/last', {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
                .then((res) => res.json())
                .then((data) => setGridData(data))
        } catch (e) {
            console.error(e)
        }
    }, [])

    useEffect(() => {
        fetchDevices(accessToken, (places: IGeolocation[]) =>
            setPlaces(
                places.filter(
                    (p) =>
                        p.geoLocation.latitude !== 0 &&
                        p.geoLocation.longitude !== 0
                )
            )
        )
    }, [accessToken])
    useEffect(() => {
        // load cluster
        if (map !== undefined && maps !== undefined) {
            const bounds = getMapBounds(maps, places)
            setGlobalBounds(bounds)
            // Fit map to bounds
            map.fitBounds(bounds)
            const { west, south, east, north } = bounds.toJSON() as {
                [x: string]: number
            }
            getCluster(places, {
                bounds: {
                    nw: { lng: west, lat: north },
                    se: { lng: east, lat: south },
                },
                zoom: map.getZoom() as number,
            })
        }
    }, [map, maps, places])
    useEffect(() => {
        if (map !== undefined && maps !== undefined && cluster !== undefined) {
            setPlacesAndCluster(
                calculatePlacesAndCluster(maps, places, cluster, map.getZoom())
            )
        }
    }, [map, maps, zoom, cluster, places])
    const calculatePlacesAndCluster = (
        maps: any,
        places: IGeolocation[],
        cluster: Function,
        zoom: number
    ): IPlaceOrCluster[] => {
        const bounds = getMapBounds(maps, places)
        const { west, south, east, north } = bounds.toJSON() as {
            [x: string]: number
        }
        return cluster({
            bounds: {
                nw: { lng: west, lat: north },
                se: { lng: east, lat: south },
            },
            zoom,
        }) as IPlaceOrCluster[]
    }
    const findNextZoomToSeperateThePoints = (position: Position) => {
        const curZoom = map.getZoom()
        const p = placesAndCluster?.find(
            (p) => p.wx === position.lng && p.wy === position.lat
        )
        if (p !== undefined && cluster !== undefined) {
            const pointsInCluster = p.points
            for (let zoom = curZoom + 1; zoom <= 18; zoom++) {
                const newCluster = calculatePlacesAndCluster(
                    maps,
                    places,
                    cluster,
                    zoom
                )

                if (
                    newCluster.some(
                        (p) =>
                            p.points.length < pointsInCluster.length &&
                            pointsInCluster?.some((pp) =>
                                p.points.some(
                                    (ppp) => pp.deviceName === ppp.deviceName
                                )
                            )
                    )
                ) {
                    return zoom
                }
            }
            return 18
        }
        return 1
    }
    const onClickMarker = (position: Position) => {
        if (map) {
            map.setZoom(findNextZoomToSeperateThePoints(position))
            map.setCenter(position)
        }
    }
    const zoomToPoint = (id: string) => {
        const target = places.filter((p) => p.deviceId === id)?.[0]
        if (
            id &&
            target?.geoLocation?.latitude &&
            target?.geoLocation?.latitude
        ) {
            map.setCenter({
                lat: target.geoLocation.latitude,
                lng: target.geoLocation.longitude,
            })
            map.setZoom(18)
        } else {
            map.fitBounds(globalBounds)
        }
    }
    // Return map bounds based on list of places
    const getMapBounds = (maps: any, places: IGeolocation[]) => {
        if (maps) {
            const bounds = new maps.LatLngBounds()
            places.forEach(({ geoLocation: { latitude, longitude } }) => {
                bounds.extend(new maps.LatLng(latitude, longitude))
            })
            return bounds
        }
    }

    // Fit map to its bounds after the api is loaded
    const apiIsLoaded = (map: any, maps: any) => {
        setMap(map)
        setMaps(maps)
        map.addListener('zoom_changed', () => {
            setZoom(map.getZoom())
        })
    }
    const getCluster = (
        geolocations: IGeolocation[],
        bounds: {
            bounds: {
                nw: { lng: number; lat: number }
                se: { lng: number; lat: number }
            }
            zoom: number
        }
    ) => {
        const sc = new supercluster(
            geolocations.map(
                ({ geoLocation: { latitude, longitude }, ...others }) => {
                    return {
                        lat: latitude,
                        lng: longitude,
                        ...others,
                    }
                }
            ),
            {
                minZoom: 1, // min zoom to generate clusters on
                maxZoom: 16, // max zoom level to cluster the points on
                radius: 40, // cluster radius in pixels
            }
        )
        setCluster(() => sc)

        const placesAndCluster = sc(bounds)
        setPlacesAndCluster(placesAndCluster)
    }
    return (
        // height is necessary. value minus header's 60px
        <>
            <Sidebar
                hoverId={hoverId}
                zoomToPoint={zoomToPoint}
                forceClickable
                defaultGeolocations={places}
                disableFetchLocation
                selectedSensor={selectedSensor}
                setSelectedSensor={setSelectedSensor}
            />
            <div
                style={{
                    height: 'calc(100vh - 60px)',
                    width: '100%',
                    position: 'relative',
                }}
            >
                <GoogleMapReact
                    bootstrapURLKeys={{
                        key: 'AIzaSyAWr-70cR0j601wTN0MEIYSaXJy2IWcC-Q',
                        language: language,
                        region: 'FR',
                    }}
                    options={{ disableDoubleClickZoom: true }}
                    defaultCenter={{ lat: 46.937, lng: 4.307 }}
                    defaultZoom={11}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) =>
                        apiIsLoaded(map, maps)
                    }
                >
                    {placesAndCluster.map(
                        ({ wx: lng, wy: lat, numPoints, points }) => {
                            const pointData = gridData?.filter(
                                (gd) => gd.id === points[0].deviceId
                            )?.[0]
                            return (
                                <Marker
                                    key={`${lat}-${lng}`}
                                    lat={lat}
                                    lng={lng}
                                    cluster={numPoints > 1}
                                    pointData={pointData}
                                    numPoints={numPoints}
                                    deviceId={
                                        numPoints === 1
                                            ? points[0].deviceId
                                            : ''
                                    }
                                    pickedInSideBar={
                                        numPoints === 1 &&
                                        selectedSensor?.deviceId ===
                                            points[0].deviceId
                                    }
                                    onHover={(hovered: boolean) => {
                                        if (numPoints === 1) {
                                            if (hovered) {
                                                setHoverId(points[0].deviceId)
                                            } else {
                                                setHoverId('')
                                            }
                                        }
                                    }}
                                    onClickMarker={() => {
                                        onClickMarker({
                                            lat,
                                            lng,
                                        })
                                        if (numPoints === 1) {
                                            setSelectedSensor(points[0])
                                        }
                                    }}
                                    dead={
                                        numPoints === 1 &&
                                        moment(
                                            points[0].lastMeasureAt
                                        ).isBefore(moment().add(-3, 'day'))
                                    }
                                />
                            )
                        }
                    )}
                </GoogleMapReact>
                <AtmoIndices groups={groups} />
            </div>
            <Grid data={gridData} onHoverId={(id: string) => setHoverId(id)} />
        </>
    )
}

export default SimpleMap
