export enum EMode {
    Auto,
    Relative,
}

export interface ILastMeasure {
    id: string
    temperature: number
    humidity: number
    co2: number
    lvoc: number
    pm1: number
    pm2dot5: number
    pm10: number
    pressure: number
    formaldehyde: number
    battery: number
    ullage: number
    [x: string]: number | string
}

export interface IDeviceMeasures {
    id: string
    temperature: IMeasureValue[]
    humidity: IMeasureValue[]
    co2: IMeasureValue[]
    lvoc: IMeasureValue[]
    pm1: IMeasureValue[]
    pm2dot5: IMeasureValue[]
    pm10: IMeasureValue[]
    pressure: IMeasureValue[]
    formaldehyde: IMeasureValue[]
    battery: IMeasureValue[]
}

export interface IDeviceMeasuresV2 {
    id: string
    time: number
    name: string
    temperature: number
    humidity: number
    co2: number
    lvoc: number
    pm1: number
    pm2dot5: number
    pm10: number
    pressure: number
    formaldehyde: number
    battery: number
}

export interface IChirpstackDeviceMeasures {
    id: string
    CreatedAt: string
    UpdatedAt: string
    deviceID: number
    temperature: number
    humidity: number
    motion: number
    light: number
    vdd: number
    dewpoint: number
    co2: number
    ullage?: number
}

export interface IMeasureValue {
    time: number
    value: number
}

export interface ILine {
    x: string | number
    y: number
    tooltipV: string
    type: string
}

export interface IField {
    key: keyof Omit<IDeviceMeasuresV2, 'id'>
    label: string
    unit: string
}

export interface IData {
    x: number
    y: number
    tooltipV: string
    type: string
}

export interface IReportData {
    co2SeriesData: { time: number; co2: number }[]
    from: string
    to: string
    week: number
    year: number
}
