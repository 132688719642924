import React from 'react'
import styled from 'styled-components'

const SensorWidget = ({
    size,
    labelLeft,
    labelRight,
    unitLeft,
    unitRight,
    percentageLeft,
    percentageRight,
}: {
    size: number
    labelLeft: string
    labelRight: string
    unitLeft: string
    unitRight: string
    percentageLeft: number
    percentageRight: number
}) => {
    return (
        <Wrapper>
            <WidgetLeft>
                <Circle
                    cx={size}
                    cy={size}
                    r={size}
                    perimeter={(2 * Math.PI * size).toFixed(0)}
                    percentage={percentageLeft}
                />
            </WidgetLeft>

            <WidgetRight>
                <Circle
                    cx={size}
                    cy={size}
                    r={size}
                    perimeter={(2 * Math.PI * size).toFixed(0)}
                    percentage={percentageRight}
                />
            </WidgetRight>
            <Data>
                <Text>
                    {labelLeft}
                    <div>{unitLeft}</div>
                </Text>
                <Text style={{ borderLeft: '2px solid #4195bf' }}>
                    {labelRight}
                    <div>{unitRight}</div>
                </Text>
            </Data>
        </Wrapper>
    )
}

export default SensorWidget
const Wrapper = styled.div`
    position: relative;
    height: 73px;
    width: 102px;
`
const Data = styled.div`
    display: grid;
    position: absolute;
    top: 0;
    place-items: center;
    height: 73px;
    width: 102px;
    grid-template-columns: 50% 50%;
`
const Text = styled.div`
    width: 100%;
    text-align: center;
`
const WidgetRight = styled.svg`
    position: absolute;
    top: 0px;
    left: 30px;
    width: 73px;
    height: 73px;
    transform: rotate(-90deg) scaleX(-1);
    z-index: 1000;
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
`

const WidgetLeft = styled.svg`
    position: absolute;
    top: 0px;
    left: 0;
    width: 73px;
    height: 73px;
    transform: rotate(90deg);
    z-index: 1000;
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
`

const Circle = styled.circle<{ perimeter: string; percentage: number }>`
    width: 100%;
    height: 100%;
    transform: translate(2px, 2px);
    fill: none;
    stroke-width: 3px;
    stroke-linecap: round;
    stroke-dashoffset: ${({ perimeter, percentage }) =>
        `calc(${perimeter} - (${perimeter} / 2 * ${percentage}) / 100);`} 
    stroke: #00a1ff;
    stroke-dasharray: ${({ perimeter }) => perimeter};
`
