import {
    Alert,
    Green,
    HighWarning,
    LightWarning,
    MediumWarning,
} from '../../theme/theme'
import React, { CSSProperties } from 'react'

export const DefaultMarkerColor = '#00bcd4'
export const CssMarkerBorderSize = 5
export const IconSize = 30

export const K_HINT_HTML_DEFAULT_Z_INDEX = 1000000
export const K_SCALE_HOVER = 1.3
export const K_SCALE_TABLE_HOVER = 1
export const K_SCALE_NORMAL = 1
export const K_MIN_CONTRAST = 0.4

export const WidthDelta = 10
// this is the border size of the css marker
export const HeightDelta = 5
export const CssMarkerWidth = 170
export const CssMarkerHeight = 60
export const heightRate = (Math.sqrt(2) - 1) / 2 + 1
export const defaultV = {
    scale: K_SCALE_NORMAL,
    hoverState: false,
    showBallonState: false,
    withText: false,
    size: {
        width: CssMarkerWidth + WidthDelta,
        height: CssMarkerHeight * heightRate + HeightDelta,
    },
    origin: { x: 0.5, y: 1 },
}

export const calcMarkerTextStyle = (scale: number): CSSProperties => {
    const K_MAX_COLOR_VALUE = 0
    const K_MIN_COLOR_VALUE = 8
    const colorV = Math.ceil(
        K_MIN_COLOR_VALUE +
            (K_MAX_COLOR_VALUE - K_MIN_COLOR_VALUE) *
                Math.min(scale / K_SCALE_NORMAL, 1)
    )
    const colorHex = colorV.toString(16)
    const colorHTML = `#${colorHex}${colorHex}${colorHex}`

    return {
        width: '100%',
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: colorHTML,
    }
}

export const calcMarkerMarkerStyle = (
    scale: number,
    zIndexStyle: any,
    markerStyle: any
) => {
    const contrast =
        K_MIN_CONTRAST +
        (1 - K_MIN_CONTRAST) * Math.min(scale / K_SCALE_NORMAL, 1)

    return {
        transform: `scale(${scale} , ${scale})`,
        WebkitTransform: `scale(${scale} , ${scale})`,
        filter: `contrast(${contrast})`,
        WebkitFilter: `contrast(${contrast})`,
        ...markerStyle,
        ...zIndexStyle,
    }
}

export const CO2Color = (v: number) => {
    if (v < 1000) {
        return Green
    }
    if (v < 2000) {
        return LightWarning
    }
    if (v < 3000) {
        return MediumWarning
    }
    if (v < 4000) {
        return HighWarning
    }
    return Alert
}

export const ColorPickerBySensorData = (v: number, sensor: string) => {
    switch (sensor) {
        case 'co2':
            return CO2Color(v)
        default:
            return DefaultMarkerColor
    }
}
export const getMarkerHolderStyle = (
    size: { width: number; height: number },
    origin: { x: number; y: number }
): CSSProperties => {
    const left = -size.width * origin.x
    const top = -size.height * origin.y
    return {
        position: 'absolute',
        width: size.width,
        height: size.height,
        left: left,
        top: top,
        cursor: 'pointer',
    }
}

export const getMarkerStyle = (
    size: { width: number; height: number },
    origin: { x: number; y: number }
) => {
    const sizeOriginX = size.width * origin.x
    const sizeOriginY = size.height * origin.y

    return {
        position: 'absolute',
        width: size.width,
        height: size.height,
        left: 0,
        top: 0,
        willChange: 'transform', // it looks like this setting make firefox random marker movements smaller
        // transition: 'transform 0.25s ease',
        transition: 'transform 0.25s cubic-bezier(0.485, 1.650, 0.545, 0.835)',
        transformOrigin: `${sizeOriginX}px ${sizeOriginY}px`,
    }
}
