import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'

import AtmoLogo from '../../assets/log_atmo_transparent.png'
import { AccessTokenCtx } from '../Context'
import {
    Alert,
    Green,
    HighWarning,
    LightWarning,
    MediumWarning,
    Red,
} from '../../theme/theme'
import insee from './insee.json'

interface AtmoIndices {
    data: {
        echeance: number
        date_echeance: string
        indice: number
        qualificatif: string
        couleur_html: string
        date_calcul: string
        commune_insee: string
        commune_nom: string
        type_valeur: string
        sous_indices: {
            polluant_nom: string
            concentration: number
            indice: number
        }[]
        polluants_majoritaires: string[]
    }[]
}
const indiceColor = (indice: number) => {
    switch (indice) {
        case 1:
            return Green
        case 2:
            return LightWarning
        case 3:
            return MediumWarning
        case 4:
            return HighWarning
        case 5:
            return Alert
        case 6:
            return Red
        case 7:
            return Red
        default:
            return '#000000'
    }
}

const getInsee = (groups: string[]) => {
    for (const group of groups) {
        const res = insee[group as keyof typeof insee]
        if (res) {
            return res
        }
    }
    return '38185'
}
const AtmoIndices = ({ groups }: { groups: string[] }) => {
    const accessToken = useContext(AccessTokenCtx)
    const [atmoIndices, setAtmoIndices] = useState<AtmoIndices>()
    const fetchAtmoIndices = async () => {
        try {
            const result = await fetch(
                `/api/v1/atmo/indices?insee=${getInsee(groups)}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'content-type': 'application/json',
                    },
                    method: 'GET',
                }
            )
            const data = await result.json()
            setAtmoIndices(data)
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        fetchAtmoIndices()
    }, [])
    const pm25 = atmoIndices?.data?.[0]?.sous_indices?.find(
        (index) => index.polluant_nom === 'PM2.5'
    )

    return (
        <Container>
            <IndexNumber color={indiceColor(pm25?.indice ?? 0)}>
                {pm25?.concentration}
            </IndexNumber>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignContent: 'center',
                    alignItems: 'flex-start',
                }}
            >
                <IndexNumberSuffix>{pm25?.polluant_nom}</IndexNumberSuffix>
                <Logo src={AtmoLogo} alt={'atmo logo'} />
            </div>
        </Container>
    )
}

export default AtmoIndices
const IndexNumber = styled.div<{ color: string }>`
    margin-left: 10px;
    font-size: 3.5rem;
    font-weight: 600;
    color: ${(props) => props.color};
`
const IndexNumberSuffix = styled.div`
    margin-left: 10px;
    font-size: 1rem;
    font-weight: 600;
    color: #fff;
`
const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    background: #bae1c5;
    box-shadow: 6px 6px 8px 0 #26a28f;
    border-radius: 22px;
    display: flex;
    align-items: flex-end;
`

const Logo = styled.img`
    width: 100px;
    height: 3rem;
    border-radius: 15px;
`
