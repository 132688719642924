import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import SensorInfo from '../assets/sensor_g.svg'
import SensorRed from '../assets/sensor_r.svg'
import { Button } from '../ui/Button'
import { IGeolocation } from '../intefaces/map'
import moment from 'moment'
import { AccessTokenCtx } from './Context'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'

const Sidebar: React.FunctionComponent<{
    defaultGeolocations?: IGeolocation[]
    disableFetchLocation?: boolean
    forceClickable?: boolean
    children?: React.ReactChild | React.ReactChildren
    zoomToPoint?: Function
    onSensorSelected?: Function
    hoverId?: string
    selectedSensor: IGeolocation
    setSelectedSensor: Function
}> = ({
    hoverId,
    children,
    zoomToPoint,
    onSensorSelected,
    forceClickable,
    defaultGeolocations,
    disableFetchLocation,
    selectedSensor,
    setSelectedSensor,
}) => {
    const accessToken = useContext(AccessTokenCtx)
    const { t, i18n } = useTranslation()
    const history = useHistory()
    const { deviceId } = useParams<{ deviceId: string }>()
    const [geolocations, setGeolocations] = useState<IGeolocation[]>([])
    const geoSource = disableFetchLocation ? defaultGeolocations : geolocations

    const selectOrClear = (id: string) => {
        const idToSelect = selectedSensor?.deviceId !== id ? id : ''
        setSelectedSensor(geoSource?.find((g) => g.deviceId === id))
        onSensorSelected?.(id)
        zoomToPoint?.(idToSelect)
    }
    useEffect(() => {
        // use this hook to set the data when first load
        if ((geolocations?.length ?? 0) > 0 && selectedSensor?.deviceId) {
            const selected = geolocations?.find(
                (g) => g.deviceId === selectedSensor?.deviceId
            )
            // when first load, we do not have device name loaded, we only get device id from url
            if (!selectedSensor?.deviceName) {
                setSelectedSensor(selected)
            }
        }
    }, [geolocations, selectedSensor])
    useEffect(() => {
        try {
            if (!disableFetchLocation) {
                fetch('/api/v3/devices', {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                })
                    .then((res) => res.json())
                    .then((data) =>
                        setGeolocations(
                            data.sort((a: IGeolocation, b: IGeolocation) =>
                                a.deviceName < b.deviceName ? -1 : 1
                            )
                        )
                    )
            }
        } catch (e) {
            console.error(e)
        }
    }, [accessToken, disableFetchLocation])
    useEffect(() => {
        if (deviceId) {
            document?.getElementById(deviceId)?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest',
            })
        }
    }, [deviceId])
    useEffect(() => {
        if (selectedSensor) {
            document?.getElementById(selectedSensor.deviceId)?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest',
            })
        }
    }, [selectedSensor, document.getElementById(selectedSensor?.deviceId)])
    useEffect(() => {
        if (hoverId) {
            document?.getElementById(hoverId)?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest',
            })
        }
    }, [hoverId])
    return (
        <Container>
            {zoomToPoint !== undefined ? (
                <Line>
                    <Button onClick={() => selectOrClear('')}>
                        {t('Zoom For All')}
                    </Button>
                </Line>
            ) : (
                <></>
            )}
            <SectionHeader>
                <SectionTitle> {t('Sensors')}</SectionTitle>
            </SectionHeader>
            <SectionList>
                {geoSource?.map(
                    ({
                        deviceName,
                        deviceId,
                        geoLocation: { location },
                        lastMeasureAt,
                    }) => {
                        const dead = moment(lastMeasureAt).isBefore(
                            moment().add(-3, 'day')
                        )
                        return (
                            <SectionContent
                                id={deviceId}
                                key={deviceId}
                                selected={selectedSensor?.deviceId === deviceId}
                                onClick={() => {
                                    if (forceClickable || !dead) {
                                        selectOrClear(deviceId)
                                    }
                                }}
                                onDoubleClick={(e: React.MouseEvent) => {
                                    if (!dead) {
                                        e.preventDefault()
                                        history.push(
                                            `/${i18n.language}/dashboard/${deviceId}`
                                        )
                                    }
                                }}
                                dead={!forceClickable && dead}
                                hovered={hoverId === deviceId}
                            >
                                <SensorIcon
                                    title={t(
                                        dead ? 'no data since 3 Days' : 'live'
                                    )}
                                    dead={dead}
                                />
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        maxWidth: '155px',
                                        overflowWrap: 'break-word',
                                    }}
                                >
                                    <div>
                                        {location ? location : deviceName}
                                    </div>
                                    <sub
                                        style={{
                                            fontSize: '10px',
                                            color: 'grey',
                                        }}
                                    >
                                        {location ? deviceName : deviceId}
                                    </sub>
                                </div>
                            </SectionContent>
                        )
                    }
                )}
            </SectionList>
        </Container>
    )
}
export default Sidebar
const Container = styled.div`
    display: flex;
    flex: 0 0 250px;
    justify-content: start;
    flex-direction: column;
    align-items: center;
    background-color: rgb(249 249 249);
    border-right: 1px solid #d6d6d6;
    overflow-y: hidden;
    height: calc(100vh - 60px);
    box-sizing: content-box;
`

const Line = styled.div`
    margin: 10px 0;
`
const SectionHeader = styled.div`
    display: flex;
    justify-content: start;
    width: 90%;
    margin: 10px 0 10px 20px;
`
const SectionTitle = styled.div`
    font-weight: 500;
    font-size: 18px;
    opacity: 1;
    text-align: left;
    color: rgba(153, 153, 153, 1);
`
const SectionList = styled.div`
    display: flex;
    width: 90%;
    justify-content: start;
    flex-direction: column;
    overflow-y: auto;
`
const SectionContent = styled.div<{
    selected?: boolean
    dead: boolean
    hovered: boolean
}>`
    display: flex;
    margin: 10px;
    width: 90%;

    padding: 10px 0;
    &:hover {
        box-shadow: 0px 1px 10px rgb(0 0 0 / 25%);
    }
    ${({ hovered }) =>
        hovered ? 'box-shadow: 0px 1px 10px rgb(0 0 0 / 25%);' : ''}
    ${({ selected }) =>
        selected ? 'box-shadow: 0px 1px 10px rgb(0 0 0 / 25%);' : ''}
    cursor:pointer;
    transition: box-shadow 0.3s ease-in-out;
    ${({ dead }) => (dead ? 'cursor:not-allowed;' : '')}
    align-items: center;
`
const SensorIcon = styled.div<{ dead: boolean }>`
    width: 25px;
    height: 25px;
    margin: 0px 10px;
    background-image: url(${({ dead }) => (dead ? SensorRed : SensorInfo)});
`
