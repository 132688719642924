import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { IBuilding } from './Interface'
import { Button } from '../../../ui/Button'
import { ReactComponent as SensorSvg } from '../../../assets/sensor.svg'
import SimpleChart from './SimpleChart'
import { Green, Red } from '../../../theme/theme'
const PlanSideBar = ({
    buildings,
    pickedBuildingId,
    setPickedBuildingId,
}: {
    buildings: IBuilding[]
    pickedBuildingId: number
    setPickedBuildingId: Function
}) => {
    const { t } = useTranslation()
    return (
        <Container>
            <SectionHeader>
                <SectionTitle> {t('Sensors')}</SectionTitle>
            </SectionHeader>
            <Line>
                <Button onClick={() => setPickedBuildingId(0)}>
                    {t('Global View')}
                </Button>
            </Line>
            <SectionList>
                {buildings?.map(({ ID, name, summary, history }) => (
                    <SectionContent
                        key={ID}
                        selected={pickedBuildingId === ID}
                        onClick={() => {
                            setPickedBuildingId(ID)
                        }}
                    >
                        <StyledSensorSvg
                            highLowTemperatureAlert={
                                summary.highLowTemperatureAlert
                            }
                        />
                        <SensorDetails>
                            <SensorTitle>
                                {name}
                                {history?.length > 0 ? (
                                    <SimpleChart key={ID} data={history} />
                                ) : (
                                    <></>
                                )}
                            </SensorTitle>
                            <SensorSub>{`${t(
                                'Temperature'
                            )}: ${summary.avgTemperature.toFixed(
                                1
                            )}°C`}</SensorSub>
                            <SensorSub>{`${t(
                                'Humidity'
                            )}: ${summary.avgHumidity.toFixed(1)}%`}</SensorSub>
                        </SensorDetails>
                    </SectionContent>
                ))}
            </SectionList>
        </Container>
    )
}

export default PlanSideBar

const Container = styled.div`
    display: flex;
    flex: 0 0 250px;
    justify-content: start;
    flex-direction: column;
    align-items: center;
    background-color: rgb(249 249 249);
    border-right: 1px solid #d6d6d6;
    overflow-y: hidden;
    height: calc(100vh - 60px);
    box-sizing: content-box;
`

const Line = styled.div`
    margin: 10px 0;
`
const SectionHeader = styled.div`
    display: flex;
    justify-content: start;
    width: 90%;
    margin: 10px 0 10px 20px;
`
const SectionTitle = styled.div`
    font-weight: 500;
    font-size: 18px;
    opacity: 1;
    text-align: left;
    color: rgba(153, 153, 153, 1);
`
const SectionList = styled.div`
    display: flex;
    width: 90%;
    justify-content: start;
    flex-direction: column;
    overflow-y: auto;
`
const SectionContent = styled.div<{
    selected?: boolean
    hovered?: boolean
}>`
    display: flex;
    margin: 10px;
    width: 90%;

    padding: 10px 0;
    &:hover {
        box-shadow: 0px 1px 10px rgb(0 0 0 / 25%);
    }
    ${({ hovered }) =>
        hovered ? 'box-shadow: 0px 1px 10px rgb(0 0 0 / 25%);' : ''}
    ${({ selected }) =>
        selected ? 'box-shadow: 0px 1px 10px rgb(0 0 0 / 25%);' : ''}
    cursor:pointer;
    transition: box-shadow 0.3s ease-in-out;
    align-items: center;
`
const PlanIcon = styled.div<{}>`
    width: 25px;
    height: 25px;
    margin: 0px 10px;
`

const SensorDetails = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 155px;
    overflow-wrap: break-word;
`
const SensorTitle = styled.div`
    display: grid;
    grid-template-columns: 100px auto;
    justify-items: flex-start;
    align-items: center;
`
const SensorSub = styled.sub`
    font-size: 10px;
    color: grey;
`

const StyledSensorSvg = styled(SensorSvg)<{ highLowTemperatureAlert: boolean }>`
    fill: ${({ highLowTemperatureAlert }) =>
        highLowTemperatureAlert ? Red : Green};
    width: 30px;
    height: auto;
    padding: 5px;
}
`
