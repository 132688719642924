import React, { useState } from 'react'
import SimpleMap from './component/GoogleMap'
import Header from './component/Header'
import styled from 'styled-components'
import Dashboard from './component/Dashboard'
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
    useRouteMatch,
} from 'react-router-dom'
import Login from './component/Login'
import { setGlobalLanguage } from './i18n'
import DeviceOverrideInfo from './component/DeviceOverrideInfo'
import InDoor from './component/InDoor/InDoor'
import DashboardChirpstack from './component/IoT/DashboardChirpstack'
import ReportBody from './component/Report/ReportBody'
import Report from './component/Report/Report'

const AppWithLanguagePath = ({ language }: { language: string }) => {
    const { path } = useRouteMatch()
    const [showSignOutButton, setShowSignOutButton] = useState(false)
    const [groups, setGroups] = useState<string[]>([])
    const [loading, setLoading] = useState(true)
    setGlobalLanguage(language, () => {
        setLoading(false)
    })
    return loading ? (
        <></>
    ) : (
        <div className="App">
            <Header
                signedIn={showSignOutButton}
                onSignOut={() => setShowSignOutButton(false)}
                groups={groups}
            >
                <h1>Cleair</h1>
            </Header>
            <Login
                onSignedIn={(groups: string[]) => {
                    setShowSignOutButton(true)
                    setGroups(groups)
                }}
            >
                <Container>
                    <Switch>
                        <Route exact path={`${path}/`}>
                            <SimpleMap language={language} groups={groups} />
                        </Route>
                        <Route exact path={`${path}/indoor`}>
                            <InDoor groups={groups} />
                        </Route>
                        <Route exact path={`${path}/dashboard`}>
                            <Dashboard />
                        </Route>
                        <Route path={`${path}/dashboard/:deviceId`}>
                            <Dashboard />
                        </Route>
                        <Route exact path={`${path}/dashboard-indoor`}>
                            <DashboardChirpstack />
                        </Route>
                        <Route
                            exact
                            path={`${path}/dashboard-indoor/:deviceId`}
                        >
                            <DashboardChirpstack />
                        </Route>
                        <Route path={`${path}/admin`}>
                            <DeviceOverrideInfo />
                        </Route>
                        <Route exact path={`${path}/report`}>
                            <Report />
                        </Route>
                        <Route path={`${path}/*`}>
                            <Redirect to={`/${language}`} />
                        </Route>
                    </Switch>
                </Container>
            </Login>
        </div>
    )
}
const App = () => {
    return (
        <Switch>
            <Route path="/fr">
                <AppWithLanguagePath language={'fr'} />
            </Route>
            <Route path="/en">
                <AppWithLanguagePath language={'en'} />
            </Route>
            <Route path="*">
                <Redirect to="/fr" />
            </Route>
        </Switch>
    )
}

const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
`
const RouteApp = () => (
    <Router>
        <App />
    </Router>
)
export default RouteApp
