import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import PlanSideBar from './components/PlanSideBar'
import { IBuilding } from './components/Interface'
import { AccessTokenCtx } from '../Context'
import { PlanMonitor } from './components/PlanMonitor'
import { GlobalMonitor } from './components/GlobalMonitor'
// fetch every 1 min
const interval = 30 * 1000
const InDoor = ({ groups }: { groups: string[] }) => {
    const isAdmin = () => groups.some((g) => g === 'admin')
    const accessToken = useContext(AccessTokenCtx)
    const [buildings, setBuildings] = useState<IBuilding[]>([])
    const [fetchInterval, setFetchInterval] = useState<NodeJS.Timeout>()
    const [pickedBuildingId, setPickedBuildingId] = useState(0)
    const [pickedSensorId, setPickedSensorId] = useState(0)
    const [pickedPlanId, setPickedPlanId] = useState(0)
    const fetchBuildings = (accessToken: string) => {
        try {
            fetch('/api/v2/buildings', {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    setBuildings(data)
                })
        } catch (e) {
            console.error(e)
        }
    }
    const updateIndoorPosition = (planId: number, indoorPosition: number[]) => {
        try {
            if (!isAdmin()) {
                return
            }
            fetch('/api/v1/device/indoor', {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                method: 'POST',
                body: JSON.stringify({
                    deviceId: pickedSensorId,
                    x: indoorPosition[0],
                    y: indoorPosition[1],
                    planId,
                }),
            }).then(() => {
                fetchBuildings(accessToken)
            })
        } catch (e) {
            console.error(e)
        }
    }
    const updateSensorIndoorPosition =
        (planId: number) => (indoorPosition: number[]) => {
            if (!pickedSensorId) {
                return
            }
            updateIndoorPosition(planId, indoorPosition)
        }
    useEffect(() => {
        fetchBuildings(accessToken)
        setFetchInterval(
            setInterval(() => {
                fetchBuildings(accessToken)
            }, interval)
        )
        return () => {
            if (fetchInterval !== undefined) {
                clearInterval(fetchInterval)
            }
        }
    }, [accessToken])
    return (
        <Wrapper>
            <PlanSideBar
                buildings={buildings}
                pickedBuildingId={pickedBuildingId}
                setPickedBuildingId={(id: number) => {
                    setPickedBuildingId(id)
                    setPickedPlanId(
                        buildings.find((b) => b.ID === id)?.plans?.[0]?.ID ?? 0
                    )
                }}
            />
            {pickedBuildingId ? (
                <PlanMonitor
                    isAdmin={isAdmin()}
                    pickedPlanId={pickedPlanId}
                    pickedSensorId={pickedSensorId}
                    setPickedSensorId={(id: number) => setPickedSensorId(id)}
                    setPickedPlanId={(id: number) => setPickedPlanId(id)}
                    updateSensorIndoorPosition={updateSensorIndoorPosition}
                    plans={
                        buildings.find((b) => b.ID === pickedBuildingId)
                            ?.plans ?? []
                    }
                />
            ) : (
                <GlobalMonitor
                    buildings={buildings}
                    isAdmin={isAdmin()}
                    refresh={() => {
                        fetchBuildings(accessToken)
                    }}
                />
            )}
        </Wrapper>
    )
}

export default InDoor

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 250px calc(100vw - 250px);
`
