import React, { useEffect, useState } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import fr from 'date-fns/locale/fr'
import en from 'date-fns/locale/en-US'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'
import moment, { Moment } from 'moment'

import { EMode } from '../../intefaces/measure'
import { EuiDatePicker, EuiDatePickerRange } from '@elastic/eui'

registerLocale('fr', fr)
registerLocale('en', en)
const DatePickers = ({
    mode,
    onRelativeMode,
    onRelativeDateChange,
    onAutoMode,
}: {
    mode: EMode
    onRelativeMode: Function
    onRelativeDateChange: Function
    onAutoMode: Function
}) => {
    const { t, i18n } = useTranslation()
    const [startDate, setStartDate] = useState<Moment>(moment().add(-3, 'd'))
    const [endDate, setEndDate] = useState<Moment>(moment())
    useEffect(() => {
        onRelativeDateChange([startDate.toDate(), endDate.toDate()])
    }, [startDate, endDate])
    return (
        <Wrapper>
            <PickerType
                enabled={mode === EMode.Auto}
                onClick={() => {
                    onAutoMode()
                }}
            >
                {t('Auto Refresh') + ' '} (1min)
            </PickerType>
            <PickerType
                enabled={mode === EMode.Relative}
                onClick={() => {
                    onRelativeMode([startDate.toDate(), endDate.toDate()])
                }}
            >
                {t('Relative')}
            </PickerType>

            {mode === EMode.Relative && (
                <EuiDatePickerRange
                    startDateControl={
                        <EuiDatePicker
                            selected={startDate}
                            onChange={(date) => {
                                if (date) {
                                    setStartDate(date)
                                }
                            }}
                            startDate={startDate}
                            endDate={endDate}
                            isInvalid={startDate > endDate}
                            aria-label="Start date"
                            showTimeSelect
                            locale={i18n.language}
                        />
                    }
                    endDateControl={
                        <EuiDatePicker
                            selected={endDate}
                            onChange={(date) => {
                                if (date) {
                                    setEndDate(date)
                                }
                            }}
                            startDate={startDate}
                            endDate={endDate}
                            isInvalid={startDate > endDate}
                            aria-label="End date"
                            showTimeSelect
                            locale={i18n.language}
                        />
                    }
                />
            )}
        </Wrapper>
    )
}

export default DatePickers

const PickerType = styled.div<{ enabled?: boolean }>`
    box-sizing: content-box;
    height: 30px;
    line-height: 30px;
    width: auto;
    ${({ enabled }) =>
        enabled
            ? `
            background: linear-gradient(0deg, rgb(133 180 255) 0%,rgb(173 224 255) 100%);
            box-shadow: 0px 1px 10px rgb(0 0 0 / 25%);`
            : ''}
    text-align: center;
    margin: 0 10px;
    padding: 5px 10px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    font-size: 15px;
    cursor: pointer;
`
const DatePickerTitle = styled.div`
    margin-right: 5px;
`
const RangeWrapper = styled.div`
    display: flex;
    margin-right: 5px;
    height: 30px;
    line-height: 30px;
    margin: 0 10px;
    padding: 5px 10px;
`
const Wrapper = styled.div`
    box-sizing: content-box;
    display: flex;
`
