import React, { useEffect, useState } from 'react'
import { Annotation, Axis, Chart, Coord, Interval, Tooltip } from 'bizcharts'
import styled from 'styled-components'
import {
    Alert,
    DefaultColor,
    dropShaw,
    Gray,
    HighWarning,
} from '../../theme/theme'

const colorPicker = (v: number, max: number, startColor: string) => {
    if (v < max * 0.33) {
        return startColor
    }
    if (v < max * 0.66) {
        return HighWarning
    }
    return Alert
}
const transparency = (baseColor: string, percent: number) => {
    return baseColor + Math.floor(255 * (0.5 + 0.5 * percent)).toString(16)
}
const VerticalGauge = ({
    title,
    value,
    width = 300,
    height = 560,
    color = DefaultColor,
}: {
    title: string
    value: number
    width?: number
    height?: number
    color?: string
}) => {
    const [matcherMini] = useState(window.matchMedia('(max-height: 768px)'))
    const [matcherMedium] = useState(window.matchMedia('(max-height: 1080px)'))
    const [mini, setMini] = useState(matcherMini.matches)
    const [medium, setMedium] = useState(matcherMedium.matches)
    const heightChart = (height / 20) * 11

    useEffect(() => {
        matcherMini.addEventListener('change', (ev) => {
            setMini(ev.matches)
        })
        matcherMedium.addEventListener('change', (ev) => {
            setMedium(ev.matches)
        })
        return () => {
            matcherMini.removeEventListener('change', (ev) => {
                setMini(ev.matches)
            })
            matcherMedium.removeEventListener('change', (ev) => {
                setMedium(ev.matches)
            })
        }
    })
    return (
        <Container height={height - 30}>
            <Title>{title}</Title>
            <CurValue height={height - 30 - 60 - heightChart}>{value}</CurValue>
            <Chart
                height={heightChart}
                width={width - 30}
                data={generateData(value)}
                autoFit
                scale={{
                    y: {
                        min: 0, // 定义数值范围的最小值
                        max: 150, // 定义数值范围的最大值
                    },
                    x: {
                        min: 0, // 定义数值范围的最小值
                        max: 100, // 定义数值范围的最大值
                    },
                }}
            >
                <Axis visible={false} />
                <Tooltip visible={false} />
                <Interval
                    animate={{ appear: false }}
                    position="x*y*percent"
                    style={[
                        'x*y*percent',
                        (x, y, percent) => ({
                            lineWidth: 20,
                            width: 1,
                            height: 1,
                            fill: percent
                                ? transparency(
                                      colorPicker(value, 100, color),
                                      percent
                                  )
                                : Gray,
                        }),
                    ]}
                />
                <Coord transpose={true} />
                <Annotation.DataMarker
                    position={[100, 100]}
                    autoAdjust={false}
                    text={{
                        content: '100',
                        style: {
                            textAlign: 'left',
                            fontSize: mini ? 8 : medium ? 12 : 16,
                        },
                    }}
                    line={{
                        length: -2,
                    }}
                    point={{
                        style: {
                            opacity: 0,
                        },
                    }}
                    direction="downward"
                />
                <Annotation.DataMarker
                    position={[0, 100]}
                    autoAdjust={false}
                    text={{
                        content: '0',
                        style: {
                            textAlign: 'left',
                            fontSize: mini ? 8 : medium ? 12 : 16,
                        },
                    }}
                    line={{
                        length: 0,
                    }}
                    point={{
                        style: {
                            opacity: 0,
                        },
                    }}
                    direction="upward"
                />
            </Chart>
        </Container>
    )
}

const generateData = (cur: number) => {
    const n = 100
    const base = Math.floor(cur)
    const percent = cur - base
    return new Array(n).fill(0).map((v, i) => ({
        x: (100 / n) * (i + 1),
        y: [50, 100],
        percent: i <= base ? 1 : i === base + 1 ? percent : 0,
    }))
}

export default VerticalGauge

const Container = styled.div<{ height: number }>`
    ${dropShaw(10)};
    margin: 5px;
    padding: 10px;
    height: ${({ height }) => height}px;
    box-sizing: content-box;
`
const Title = styled.div`
    height: 40px;
    padding: 10px;
    line-height: 40px;
    text-align: left;
    font-weight: 500;
    font-size: 18px;
    @media (max-height: 1080px) {
        font-size: 18px;
    }
    @media (max-height: 768px) {
        font-size: 12px;
    }
`
const CurValue = styled.div<{ height: number }>`
    height: ${({ height }) => height}px;
    line-height: ${({ height }) => height * 2 - 24 - 5}px;
    text-align: center;
    font-weight: bold;
    font-size: 24px;

    @media (max-height: 1080px) {
        font-size: 18px;
        line-height: ${({ height }) => height * 2 - 18 - 5}px;
    }
    @media (max-height: 768px) {
        font-size: 12px;
        line-height: ${({ height }) => height * 2 - 12 - 5}px;
    }
}
`
