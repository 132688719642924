import React, { useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import LoadingBar from 'react-top-loading-bar'

import moment from 'moment'
import {
    EMode,
    IData,
    IDeviceMeasuresV2,
    IField,
    ILine,
} from '../intefaces/measure'
import Gauge from './DashboardUnits/Gauge'
import VerticalGauge from './DashboardUnits/VerticalGauge'
import {
    Alert,
    DefaultColor,
    Green,
    headerHeight,
    HighWarning,
    LightWarning,
    MediumWarning,
    sideBarWidth,
} from '../theme/theme'
import DashLineChart from './DashboardUnits/DashLineChart'
import DashBarChart from './DashboardUnits/DashBarChart'
import Sidebar from './SideBar'
import { AccessTokenCtx } from './Context'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import DatePickers from './DashboardUnits/DatePicker'
import { exportDataToXlsx } from '../utils/export'
import DownloadSVG from '../assets/download.svg'
import { IGeolocation } from '../intefaces/map'

const line1Field: IField[] = [
    { key: 'temperature', label: 'Temperature', unit: '[°C]' },
    { key: 'humidity', label: 'Humidity', unit: '[%]' },
]
const line2Field: IField[] = [
    { key: 'lvoc', label: 'TVOC', unit: '[ppb]' },
    { key: 'co2', label: 'CO2', unit: '[ppm]' },
]
const line3Field: IField[] = [
    { key: 'pm2dot5', label: 'PM 2.5', unit: '[µg/m³]' },
    { key: 'pm10', label: 'PM 10.0', unit: '[µg/m³]' },
]
const getDashboardSizing = () => {
    const totalHeight = window.innerHeight - headerHeight - 40 - 45
    const totalWidth = window.innerWidth - sideBarWidth - 60
    const dividedBy = window.innerHeight < 1080 ? 4 : 5
    const partialHeight = Math.floor(totalHeight / dividedBy)
    const gaugeWidth = partialHeight * 1.4
    const chartWidth = totalWidth - gaugeWidth * 2
    const verticalGaugeHeight = partialHeight * (dividedBy - 2)
    return { gaugeWidth, partialHeight, chartWidth, verticalGaugeHeight }
}
// fetch every 1 min
const interval = 60 * 1000

interface IIcone {
    todayCO2: {
        n0: number
        n1: number
        n2: number
        total: number
        icone: number
    }
    yesterdayCO2: {
        n0: number
        n1: number
        n2: number
        total: number
        icone: number
    }
}

const Dashboard = () => {
    const { t } = useTranslation()
    const accessToken = useContext(AccessTokenCtx)
    const { path } = useRouteMatch()
    const history = useHistory()
    const { deviceId } = useParams<{ deviceId: string }>()
    const [data, setData] = useState<IDeviceMeasuresV2[]>([])
    const [icone, setIcone] = useState<{ value: string; hint: string }>({
        value: '',
        hint: '',
    })
    const [lines, setLines] = useState<{ [x: string]: ILine[] }>({})
    const [sizing, setSizing] = useState(getDashboardSizing())
    const [selectedSensor, setSelectedSensor] = useState<IGeolocation>({
        deviceId,
    } as IGeolocation)
    const [fetchInterval, setFetchInterval] = useState<NodeJS.Timeout>()
    const [mode, setMode] = useState<EMode>(EMode.Auto)
    const [exporting, setExporting] = useState(false)
    const [relativeDate, setRelativeDate] = useState<[Date, Date]>([
        new Date(),
        new Date(),
    ])
    const ref = useRef<any>(null)
    const clearFetch = () => {
        if (fetchInterval !== undefined) {
            clearInterval(fetchInterval)
        }
    }
    const getSortData = (data: any) => {
        if (selectedSensor.sensorType === 'nemo') {
            return data?.time
        } else {
            return new Date(data?.CreatedAt).getTime() / 1000
        }
    }
    const getApi = () => {
        if (selectedSensor.sensorType === 'nemo') {
            return `/api/v2/device/measures`
        } else {
            return `/api/v1/chirpstack/measures`
        }
    }
    useEffect(() => {
        window.addEventListener('resize', () => {
            setSizing(getDashboardSizing())
        })
        return () => {
            window.removeEventListener('resize', () => {
                setSizing(getDashboardSizing())
            })
        }
    }, [])
    useEffect(() => {
        if (mode === EMode.Relative) {
            const subscription = { subscribed: true }
            fetchData({
                start: relativeDate[0],
                end: relativeDate[1],
                subscription,
            })
            return () => {
                subscription.subscribed = false
            }
        }
    }, [mode, relativeDate, selectedSensor])
    useEffect(() => {
        clearFetch()
        if (selectedSensor.deviceId !== '') {
            if (mode === EMode.Auto) {
                const subscription = { subscribed: true }
                fetchData({ subscription })
                setFetchInterval(
                    setInterval(() => {
                        fetchData({})
                    }, interval)
                )
                return () => {
                    subscription.subscribed = false
                    if (fetchInterval !== undefined) {
                        clearInterval(fetchInterval)
                    }
                }
            }
        }
    }, [selectedSensor, mode])
    const fetchData = ({
        start,
        end,
        subscription,
    }: {
        start?: Date
        end?: Date
        subscription?: { subscribed: boolean }
    }) => {
        if (!selectedSensor?.deviceId) {
            return
        }
        try {
            ref?.current?.continuousStart(10, 100)
            fetch(
                `${getApi()}?start=${
                    start
                        ? moment(start).unix()
                        : moment(new Date()).add(-3, 'days').unix()
                }&end=${end ? moment(end).unix() : ''}&deviceSerialNumber=${
                    selectedSensor.deviceId
                }`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            )
                .then((res) => res.json())
                .then((data) => {
                    ref?.current?.complete()
                    subscription?.subscribed && setData(data)
                })
        } catch (e) {
            console.error(e)
        }
    }
    const onSensorSelected = (id: string) => {
        history.push(path.replace('/:deviceId', '') + '/' + id)
    }

    const ICONE = () => {
        const todayStart = moment().startOf('day').add(7.5, 'h')
        const todayEnd = moment().startOf('day').add(17.5, 'h')
        const yesterdayStart = moment().startOf('day').add(-16.5, 'h')
        const yesterdayEnd = moment().startOf('day').add(-6.5, 'h')
        const co2In3Days =
            data?.sort((a, b) => getSortData(b) - getSortData(a)) ?? []
        const icone: IIcone = co2In3Days?.reduce(
            (acc, c) => {
                let target
                if (
                    moment.unix(getSortData(c)).isBetween(todayStart, todayEnd)
                ) {
                    target = acc.todayCO2
                }
                if (
                    moment
                        .unix(getSortData(c))
                        .isBetween(yesterdayStart, yesterdayEnd)
                ) {
                    target = acc.yesterdayCO2
                }
                if (target !== undefined) {
                    if (c.co2 < 1000) {
                        target.n0 += 1
                    } else if (c.co2 < 1700) {
                        target.n1 += 1
                    } else {
                        target.n2 += 1
                    }
                    target.total += 1
                }
                return acc
            },
            {
                todayCO2: {
                    n0: 0,
                    n1: 0,
                    n2: 0,
                    total: 0,
                    icone: 0,
                },
                yesterdayCO2: {
                    n0: 0,
                    n1: 0,
                    n2: 0,
                    total: 0,
                    icone: 0,
                },
            }
        )
        // when data point if not enough, we return -1 which indicates that the value is not available
        const calculator = (target: keyof IIcone) => {
            if (icone[target].total < 50) {
                icone[target].icone = -1
                return
            }
            icone[target].icone =
                2.5 *
                Math.log2(
                    (icone[target].n1 + 3 * icone[target].n2) /
                        icone[target].total +
                        1
                )
        }
        Object?.keys(icone)?.forEach((k) => calculator(k as keyof IIcone))
        return icone
    }
    const dataSelector = (source: IField[], max: number = Infinity) =>
        source.reduce(
            (acc: IData[], cur) => [
                ...acc,
                ...(data
                    ?.sort((a, b) => getSortData(b) - getSortData(a))
                    ?.map(
                        (mv) =>
                            ({
                                x: getSortData(mv),
                                y:
                                    mv[cur.key as keyof IDeviceMeasuresV2] > max
                                        ? max
                                        : mv[
                                              cur.key as keyof IDeviceMeasuresV2
                                          ],
                                tooltipV: moment
                                    .unix(getSortData(mv))
                                    .format('YYYY/MM/DD HH:mm:ss'),
                                type: `${t(cur.label)} ${cur.unit}`,
                            } as IData)
                    ) ?? []),
            ],
            []
        )
    const lastRecord = (key: keyof Omit<IDeviceMeasuresV2, 'id' | 'name'>) =>
        data?.sort((a, b) => getSortData(b) - getSortData(a))?.[0]?.[key] ?? 0
    useEffect(() => {
        setLines({
            line1: dataSelector(line1Field),
            line2: dataSelector(line2Field),
            line3: dataSelector(line3Field, 1000),
        })
        const icone = ICONE()
        if (icone.todayCO2.icone >= 0) {
            setIcone({
                value: `ICONE: ${Math.round(icone.todayCO2.icone)}`,
                hint: t('calculated at ') + moment().format(t('YYYY/MM/DD')),
            })
        } else if (icone.yesterdayCO2.icone >= 0) {
            setIcone({
                value: `${t('ICONE D-1')}: ${Math.round(
                    icone.yesterdayCO2.icone
                )}`,
                hint:
                    t('calculated at ') +
                    moment()
                        .startOf('day')
                        .add(-1, 'd')
                        .format(t('YYYY/MM/DD')),
            })
        } else {
            setIcone({ value: `ICONE: /`, hint: '' })
        }
    }, [data])
    return (
        <>
            <LoadingBar progress={0} color={DefaultColor} ref={ref} />
            <Sidebar
                onSensorSelected={onSensorSelected}
                selectedSensor={selectedSensor}
                setSelectedSensor={setSelectedSensor}
            />
            <Container>
                <FlexRow style={{ justifyContent: 'space-between' }}>
                    <DatePickers
                        mode={mode}
                        onAutoMode={() => {
                            setMode(EMode.Auto)
                        }}
                        onRelativeMode={(date: [Date, Date]) => {
                            setMode(EMode.Relative)
                            setRelativeDate(date)
                        }}
                        onRelativeDateChange={(date: [Date, Date]) => {
                            setRelativeDate(date)
                        }}
                    />
                    <DownloadWrapper
                        title={t('Download Sensor Data')}
                        onClick={() => {
                            if (!exporting) {
                                exportDataToXlsx(data, icone.value)
                            }
                        }}
                    >
                        <DownloadImg src={DownloadSVG} />
                    </DownloadWrapper>
                </FlexRow>
                <FlexRow>
                    <Gauge
                        minValue={-20}
                        maxValue={50}
                        title={t('Temperature')}
                        percent={(lastRecord('temperature') - -20) / (50 - -20)}
                        gap={0.005}
                        unit={'°C'}
                        width={sizing.gaugeWidth}
                        height={sizing.partialHeight}
                    />
                    <Gauge
                        maxValue={100}
                        title={t('Relative Humidity')}
                        percent={lastRecord('humidity') / 100}
                        gap={0.005}
                        unit={'%'}
                        width={sizing.gaugeWidth}
                        height={sizing.partialHeight}
                        colors={[Green]}
                    />
                    <DashLineChart
                        title={`${t('Temperature')} / ${t('Humidity')}`}
                        width={sizing.chartWidth}
                        height={sizing.partialHeight}
                        data={lines.line1}
                    />
                </FlexRow>
                <FlexRow>
                    <Gauge
                        maxValue={10000}
                        title={t('TVOC')}
                        percent={Math.min(lastRecord('lvoc') / 10000, 1)}
                        gap={0.003}
                        unit={'ppb'}
                        colors={[
                            Green,
                            LightWarning,
                            MediumWarning,
                            HighWarning,
                            Alert,
                        ]}
                        thresholds={[0, 0.03, 0.1, 0.3, 0.99, 1]}
                        width={sizing.gaugeWidth}
                        height={sizing.partialHeight}
                    />
                    <Gauge
                        maxValue={5000}
                        additionalInfo={icone}
                        title={t('CO2')}
                        percent={lastRecord('co2') / 5000}
                        gap={0.005}
                        unit={'ppm'}
                        width={sizing.gaugeWidth}
                        height={sizing.partialHeight}
                        colors={[
                            Green,
                            LightWarning,
                            MediumWarning,
                            HighWarning,
                            Alert,
                        ]}
                        thresholds={[0, 0.2, 0.4, 0.6, 0.8, 1]}
                    />
                    <DashLineChart
                        title={`${t('TVOC')} / ${t('CO2')}`}
                        width={sizing.chartWidth}
                        height={sizing.partialHeight}
                        data={lines.line2}
                    />
                </FlexRow>
                <FlexRow>
                    <VerticalGauge
                        title={t('PM 2.5')}
                        value={lastRecord('pm2dot5')}
                        height={sizing.verticalGaugeHeight}
                        width={sizing.gaugeWidth}
                    />
                    <VerticalGauge
                        title={t('PM 10.0')}
                        value={lastRecord('pm10')}
                        height={sizing.verticalGaugeHeight}
                        width={sizing.gaugeWidth}
                        color={Green}
                    />
                    <DashLineChart
                        title={t('Particulate Matter [Histogram]')}
                        height={sizing.verticalGaugeHeight}
                        width={sizing.chartWidth}
                        data={lines.line3}
                        adjust={[{ type: 'stack' }]}
                    />
                </FlexRow>
            </Container>
        </>
    )
}

export default Dashboard

const Container = styled.div`
    position: absolute;
    width: calc(100vw - 251px);
    height: calc(100vh - 90px);
    position: absolute;
    right: 0;
    top: 70px;
`
const FlexRow = styled.div`
    display: flex;
    margin: 5px 0;
`
const DownloadWrapper = styled.div`
    margin: 5px 65px 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`
const DownloadImg = styled.img`
    width: 30px;
`
