import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import Select from 'react-select'

import { IGeolocation, IGeolocationChirpstack } from '../../intefaces/map'
import { AccessTokenCtx } from '../Context'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
interface IDeviceOptions {
    label: string
    value: string
    platform: string
    device_type: string
}

interface IWeekOptions {
    label: string
    value: number
}

const ReportSelector = ({
    fetchReportData,
}: {
    fetchReportData: (data: {
        week: number
        year: number
        identifier: string
        platform: string
        device_type: string
    }) => void
}) => {
    const { t } = useTranslation()

    const generateWeekOptions = () => {
        const options = []
        for (let i = 1; i <= 52; i++) {
            const currentWeek = moment().week(i)
            options.push({
                label: `${t('Week')} ${i} (${currentWeek.format(
                    'DD/MM/YYYY'
                )} - ${currentWeek.add(6, 'days').format('DD/MM/YYYY')})`,
                value: i,
            })
        }
        return options
    }
    const accessToken = useContext(AccessTokenCtx)
    const [selectedDeviceOption, setSelectedDeviceOption] =
        useState<IDeviceOptions>()
    const [deviceOption, setDeviceOption] = useState<IDeviceOptions[]>([])
    const [weekOption] = useState<IWeekOptions[]>(generateWeekOptions())
    const [selectedWeekOption, setSelectedWeekOption] = useState<IWeekOptions>()
    useEffect(() => {
        if (selectedDeviceOption?.value && selectedWeekOption?.value) {
            fetchReportData({
                week: selectedWeekOption.value,
                year: new Date().getFullYear(),
                identifier: selectedDeviceOption.value,
                platform: selectedDeviceOption.platform,
                device_type: selectedDeviceOption.device_type,
            })
        }
    }, [selectedDeviceOption, selectedWeekOption])
    // const fetchNemoDevices = async () => {
    //     try {
    //         const response = await fetch('/api/v3/devices', {
    //             headers: {
    //                 Authorization: `Bearer ${accessToken}`,
    //             },
    //         })
    //         const data = (await response.json()) as IGeolocation[]
    //         return data.map((device) => {
    //             return {
    //                 label: device.location
    //                     ? `${device.location}(${device.name})`
    //                     : device.name,
    //                 value: device.name,
    //                 platform: 'nemocloud',
    //                 device_type: 'nemo',
    //             }
    //         })
    //     } catch (e) {
    //         console.error(e)
    //     }
    // }
    const fetchChirpstackDevices = async () => {
        try {
            const response = await fetch('/api/v1/chirpstack/devices', {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
            const data = (await response.json()) as IGeolocationChirpstack[]
            return data.map((device) => {
                return {
                    label: device.deviceName,
                    value: device.deviceName,
                    platform: 'chirpstack',
                    device_type: 'ers',
                }
            })
        } catch (e) {
            console.error(e)
        }
    }
    useEffect(() => {
        Promise.all([
            // fetchNemoDevices(),
            fetchChirpstackDevices(),
        ]).then((values) => {
            let options: any = []
            values.forEach((value) => {
                options = [...options, ...(value ?? [])]
            })
            setDeviceOption(options)
        })
    }, [])
    return (
        <Container>
            <div>Select a device:</div>
            <Select
                width="400px"
                value={selectedDeviceOption}
                // @ts-ignore
                onChange={setSelectedDeviceOption}
                options={deviceOption}
            />

            <div>Select a week:</div>
            <Select
                value={selectedWeekOption}
                // @ts-ignore
                onChange={setSelectedWeekOption}
                options={weekOption}
            />
        </Container>
    )
}
export default ReportSelector
const Container = styled.div`
    width: 400px;
    height: calc(100vh - 64px);
`
