import React, { ReactChild, ReactChildren, useEffect } from 'react'

import Amplify, { Auth } from 'aws-amplify'
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react'
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components'
import styled from 'styled-components'

import { AccessTokenCtx } from './Context'
import { CognitoUserSession } from 'amazon-cognito-identity-js'

Amplify.configure({
    Auth: {
        region: 'eu-west-3',
        userPoolId: 'eu-west-3_iUxuHF2xg',
        userPoolWebClientId: '2jsmoppoq2jdh3m3ehu73hb8ot',
    },
})
const Login = ({
    children,
    onSignedIn,
}: {
    children: ReactChildren | ReactChild | undefined
    onSignedIn: Function
}) => {
    const [authState, setAuthState] = React.useState<AuthState>()
    const [token, setToken] = React.useState<string>()
    const refreshToken = async () => {
        const cognitoUser = await Auth.currentAuthenticatedUser()
        const currentSession = await Auth.currentSession()
        cognitoUser.refreshSession(
            currentSession.getRefreshToken(),
            (err: any, session: CognitoUserSession) => {
                setTimeout(() => {
                    setToken(session.getAccessToken().getJwtToken())
                }, 1000 * 2)
            }
        )
    }
    useEffect(() => {
        if (authState === AuthState.SignedIn) {
            const i = setInterval(refreshToken, 1000 * 60 * 60)
            return () => {
                clearInterval(i)
            }
        }
    }, [authState])
    useEffect(() => {
        return onAuthUIStateChange((nextAuthState) => {
            setAuthState(nextAuthState)
            if (nextAuthState === AuthState.SignedIn) {
                Auth.currentSession().then((session) => {
                    setTimeout(() => {
                        setToken(session.getAccessToken().getJwtToken())
                    }, 100)
                    onSignedIn(
                        session.getAccessToken().decodePayload()[
                            'cognito:groups'
                        ]
                    )
                })
            }
        })
    }, [onSignedIn])
    return authState === AuthState.SignedIn && token ? (
        <AccessTokenCtx.Provider value={token}>
            {children}
        </AccessTokenCtx.Provider>
    ) : (
        <Container >
            <AmplifyAuthenticator>
                <AmplifySignIn hideSignUp slot="sign-in" />
            </AmplifyAuthenticator>
        </Container>
    )
}
export default Login

const Container = styled.div`
    --amplify-primary-color: #4ebbfb;
    position: relative;
    display: flex;
    justify-content: center;
`
