import { ILastMeasure } from '../../intefaces/measure'
import SensorInfo from '../../assets/sensor_g.svg'
import React, { ReactNode } from 'react'
import {
    calcMarkerTextStyle,
    ColorPickerBySensorData,
    CssMarkerHeight,
    DefaultMarkerColor,
    IconSize,
} from './markerConfig'
import IconCO2 from '../../assets/co2.png'
import IconUllage from '../../assets/ullage.png'
import IconHumidity from '../../assets/humidity.png'
import IconTemperature from '../../assets/temperature.png'
import styled from 'styled-components'

interface IDisplayList {
    name: string
    key: string
    icon: string
}
const SensorDataDisplayList: IDisplayList[] = [
    {
        name: 'Temperature',
        key: 'temperature',
        icon: IconTemperature,
    },
    {
        name: 'Humidity',
        key: 'humidity',
        icon: IconHumidity,
    },
    {
        name: 'CO2',
        key: 'co2',
        icon: IconCO2,
    },
    {
        name: 'Ullage',
        key: 'ullage',
        icon: IconUllage,
    },
]

const MarkerContent = ({
    pointData,
    cluster,
    numPoints,
    scale,
}: {
    pointData: ILastMeasure
    cluster: boolean
    numPoints: number
    scale: number
}) => {
    const textStyle = calcMarkerTextStyle(scale)
    const renderSensorData = () => {
        return SensorDataDisplayList.reduce<ReactNode[]>((acc, sensor) => {
            const targetData = pointData?.[sensor.key]
            if (targetData !== undefined) {
                acc.push(
                    <div
                        key={sensor.key}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginRight: 5,
                            width: 80,
                        }}
                    >
                        <IconWrapper
                            src={sensor.icon}
                            alt={'co2'}
                            style={{
                                width: IconSize,
                                height: IconSize,
                                marginRight: 5,
                            }}
                        />
                        <div
                            style={{
                                color: ColorPickerBySensorData(
                                    pointData?.co2,
                                    sensor.key
                                ),
                            }}
                        >
                            {targetData
                                ? Number.isInteger(targetData)
                                    ? targetData
                                    : (targetData as number).toFixed(1)
                                : '/'}
                        </div>
                    </div>
                )
            }
            return acc
        }, [])
    }
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: CssMarkerHeight,
            }}
        >
            <div
                style={{
                    ...textStyle,
                    ...(cluster ? { color: DefaultMarkerColor } : {}),
                }}
            >
                {cluster ? (
                    <>
                        <img
                            src={SensorInfo}
                            style={{
                                width: IconSize,
                                height: IconSize,
                                marginRight: 10,
                            }}
                            alt={'sensor'}
                        />
                        {numPoints}
                    </>
                ) : (
                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {renderSensorData()}
                    </div>
                )}
            </div>
        </div>
    )
}

export default MarkerContent

const IconWrapper = styled.img``
