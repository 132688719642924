import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './locales/en.json'
import fr from './locales/fr.json'
import { I18n } from 'aws-amplify'
import dict from './locales/amplify-dict'

const resources = {
    en,
    fr,
}

export const setGlobalLanguage = async (
    language: string,
    callback: Function
) => {
    await I18n.putVocabularies(dict)
    await I18n.setLanguage(language)
    await i18n
        .use(initReactI18next) // passes i18n down to react-i18next
        .init({
            resources,
            lng: language,
            fallbackLng: 'en',
            keySeparator: false, // we do not use keys in form messages.welcome

            interpolation: {
                escapeValue: false, // react already safes from xss
            },
        })
    callback()
}

export default i18n
