import React, { useState } from 'react'
import i18n from 'i18next'
import { useHistory } from 'react-router-dom'
import CssMarker from './CssMarker'
import { ILastMeasure } from '../../intefaces/measure'
import {
    CssMarkerHeight,
    CssMarkerWidth,
    defaultV,
    getMarkerHolderStyle,
    K_SCALE_HOVER,
    K_SCALE_NORMAL,
} from './markerConfig'
import MarkerContent from './MarkerContent'

const Marker = ({
    cluster,
    numPoints,
    deviceId,
    onHover,
    onClickMarker,
    dead,
    pickedInSideBar,
    pointData,
}: {
    cluster: boolean
    lat: number
    lng: number
    numPoints: number
    deviceId: string
    onHover: Function
    onClickMarker: Function
    pickedInSideBar: boolean
    pointData: ILastMeasure
    dead: boolean
    $hover?: boolean //passed by google map, but not so efficient
}) => {
    const history = useHistory()
    const [isHover, setIsHover] = useState<boolean>(false)
    let scale = isHover || pickedInSideBar ? K_SCALE_HOVER : K_SCALE_NORMAL
    const markerHolderStyle = getMarkerHolderStyle(
        defaultV.size,
        defaultV.origin
    )

    return (
        <div
            style={markerHolderStyle}
            onMouseEnter={() => {
                setIsHover(true)
                onHover(true)
            }}
            onMouseLeave={() => {
                setIsHover(false)
                onHover(false)
            }}
            onClickCapture={() => {
                onClickMarker()
            }}
            onDoubleClickCapture={(e: React.MouseEvent) => {
                e.preventDefault()
                if (dead || !deviceId || cluster) {
                    return
                }
                history.push(`/${i18n.language}/dashboard/${deviceId}`)
            }}
        >
            <CssMarker
                dead={dead}
                width={CssMarkerWidth}
                height={CssMarkerHeight}
                isHover={isHover}
                scale={scale}
            >
                <MarkerContent
                    pointData={pointData}
                    cluster={cluster}
                    numPoints={numPoints}
                    scale={scale}
                />
            </CssMarker>
        </div>
    )
}

export default Marker
