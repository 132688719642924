import React, { useContext, useEffect, useMemo, useState } from 'react'
import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { GridReadyEvent } from 'ag-grid-community/dist/lib/events'
import { CellValueChangedEvent, ColumnApi, GridApi } from 'ag-grid-community'
import { AccessTokenCtx } from './Context'
import {
    fetchBuildings,
    fetchDevices,
    fetchPlans,
    updateIndoorDevice,
} from '../store/action'
import { IGeolocation } from '../intefaces/map'
import { IBuilding, IPlan } from './InDoor/components/Interface'
import ButtonWithModal from './Admin/ButtonWithModal'
import { CreatePlanForm } from './Admin/CreatePlanForm'
import { CreateBuildingForm } from './Admin/CreateBuildingForm'
import DeleteBuildingForm from './Admin/DeleteBuildingForm'
import DeletePlanForm from './Admin/DeletePlanForm'

const DeviceOverrideInfo = () => {
    const { t } = useTranslation()
    const accessToken = useContext(AccessTokenCtx)
    const [gridApi, setGridApi] = useState<GridApi>()
    const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>()
    const [rowData, setRowData] = useState<IGeolocation[]>([])
    const [plans, setPlans] = useState<IPlan[]>([])
    const [buildings, setBuildings] = useState<IBuilding[]>([])
    const planOptions = useMemo(() => {
        return [-1].concat(plans.map((plan) => plan.ID))
    }, [plans])
    useEffect(() => {
        try {
            fetchDevices(accessToken, setRowData)
            fetchBuildings(accessToken, setBuildings)
            fetchPlans(accessToken, setPlans)
        } catch (e) {
            console.error(e)
        }
    }, [])

    const updateDevice = (deviceInfo: any) => {
        try {
            return fetch('/api/v3/device', {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'content-type': 'application/json',
                },
                method: 'POST',
                body: JSON.stringify(deviceInfo),
            })
        } catch (e) {
            console.error(e)
        }
    }

    const resizeGrid = () => {
        gridColumnApi?.autoSizeAllColumns()
    }
    const onGridReady = (e: GridReadyEvent) => {
        setGridApi(e.api)
        setGridColumnApi(e.columnApi)
        e.columnApi.autoSizeAllColumns()
    }
    return (
        <div>
            <div
                style={{
                    height: 50,
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <ButtonWithModal
                    buttonText={'Create Building'}
                    title={'Create Building'}
                >
                    {(closeModal: Function) => (
                        <CreateBuildingForm
                            closeModal={closeModal}
                            refresh={() =>
                                fetchBuildings(accessToken, setBuildings)
                            }
                        />
                    )}
                </ButtonWithModal>

                <ButtonWithModal
                    title={'Create Plan'}
                    buttonText={'Create Plan'}
                >
                    {(closeModal: Function) => (
                        <CreatePlanForm
                            buildings={buildings}
                            closeModal={closeModal}
                            refresh={() => fetchPlans(accessToken, setPlans)}
                        />
                    )}
                </ButtonWithModal>
                <ButtonWithModal
                    buttonText={'Delete Building'}
                    title={'Delete Building'}
                >
                    {(closeModal: Function) => (
                        <DeleteBuildingForm
                            buildings={buildings}
                            closeModal={closeModal}
                            refresh={() => {
                                fetchBuildings(accessToken, setBuildings)
                                fetchPlans(accessToken, setPlans)
                                fetchDevices(accessToken, setRowData)
                            }}
                        />
                    )}
                </ButtonWithModal>
                <ButtonWithModal
                    buttonText={'Delete Plan'}
                    title={'Delete Plan'}
                >
                    {(closeModal: Function) => (
                        <DeletePlanForm
                            buildings={buildings}
                            plans={plans}
                            closeModal={closeModal}
                            refresh={() => {
                                fetchBuildings(accessToken, setBuildings)
                                fetchPlans(accessToken, setPlans)
                                fetchDevices(accessToken, setRowData)
                            }}
                        />
                    )}
                </ButtonWithModal>
            </div>
            <AgGridWrapper className="ag-theme-alpine">
                <AgGridReact
                    // @ts-ignore
                    components={{ numericCellEditor: getNumericCellEditor() }}
                    onGridReady={onGridReady}
                    rowData={rowData}
                    onRowDataChanged={() => {
                        resizeGrid()
                    }}
                    defaultColDef={{
                        flex: 1,
                        minWidth: 110,
                        editable: true,
                        resizable: true,
                    }}
                    onCellValueChanged={(param: CellValueChangedEvent) => {
                        if (param.colDef.headerName === 'Plan') {
                            updateIndoorDevice(accessToken, {
                                devEUI: param.data.deviceId,
                                planId: param.value,
                                sensorType: param.data.sensorType,
                            })
                        } else {
                            updateDevice(param.data)
                        }
                    }}
                >
                    <AgGridColumn
                        headerName={t('Name')}
                        field="deviceName"
                        resizable
                        filter
                        sortable
                        pinned
                        sort={'asc'}
                        editable={false}
                    />
                    <AgGridColumn
                        headerName={t('Device ID')}
                        field="deviceId"
                        resizable
                        filter
                        sortable
                        pinned
                        sort={'asc'}
                        editable={false}
                    />
                    <AgGridColumn
                        headerName={t('Sensor Type')}
                        field="sensorType"
                        resizable
                        filter
                        sortable
                        pinned
                        sort={'asc'}
                        editable={false}
                    />
                    <AgGridColumn
                        headerName={t('Location')}
                        field="geoLocation.location"
                        resizable
                        filter
                        sortable
                    />
                    <AgGridColumn
                        headerName={t('Latitude')}
                        field="geoLocation.latitude"
                        resizable
                        filter
                        sortable
                        cellEditor="numericCellEditor"
                    />
                    <AgGridColumn
                        headerName={t('Longitude')}
                        field="geoLocation.longitude"
                        resizable
                        filter
                        sortable
                        cellEditor="numericCellEditor"
                    />
                    <AgGridColumn
                        headerName={t('Access Group')}
                        field="group"
                        resizable
                        filter
                        sortable
                    />
                    <AgGridColumn
                        headerName={t('Plan')}
                        field="planId"
                        resizable
                        filter
                        sortable
                        valueFormatter={(params) => {
                            if (params.value === -1) {
                                return 'None'
                            }
                            const plan = plans.find(
                                (plan) => plan.ID === params.value
                            )
                            return plan?.name ?? ''
                        }}
                        cellEditor="agSelectCellEditor"
                        cellEditorParams={{
                            values: planOptions,
                        }}
                    />
                </AgGridReact>
            </AgGridWrapper>
        </div>
    )
}

export default DeviceOverrideInfo

const AgGridWrapper = styled.div`
    width: 100vw;
    height: calc(100vh - 61px);
    transition: max-height 1s;
`

function getNumericCellEditor() {
    function isCharNumeric(charStr: any, value: string) {
        return /\d/.test(charStr) || (charStr === '.' && !/\./.test(value))
    }

    function isKeyPressedNumeric(event: any, value: string) {
        var charCode = getCharCodeFromEvent(event)
        var charStr = String.fromCharCode(charCode)
        return isCharNumeric(charStr, value)
    }

    function getCharCodeFromEvent(event: any) {
        event = event || window.event
        return typeof event.which === 'undefined' ? event.keyCode : event.which
    }

    function NumericCellEditor() {}

    NumericCellEditor.prototype.init = function (params: any) {
        this.focusAfterAttached = params.cellStartedEdit
        this.eInput = document.createElement('input')
        this.eInput.style.width = '100%'
        this.eInput.style.height = '100%'
        this.eInput.style.border = '0px'
        this.eInput.value = isCharNumeric(params.charPress, this.eInput.value)
            ? params.charPress
            : params.value
        var that = this
        this.eInput.addEventListener('keypress', function (event: any) {
            if (!isKeyPressedNumeric(event, that.eInput.value)) {
                that.eInput.focus()
                if (event.preventDefault) event.preventDefault()
            }
        })
    }
    NumericCellEditor.prototype.getGui = function () {
        return this.eInput
    }
    NumericCellEditor.prototype.afterGuiAttached = function () {
        if (this.focusAfterAttached) {
            this.eInput.focus()
            this.eInput.select()
        }
    }
    NumericCellEditor.prototype.isCancelBeforeStart = function () {
        return this.cancelBeforeStart
    }
    NumericCellEditor.prototype.isCancelAfterEnd = function () {}
    NumericCellEditor.prototype.getValue = function () {
        return parseFloat(this.eInput.value)
    }
    NumericCellEditor.prototype.focusIn = function () {
        var eInput = this.getGui()
        eInput.focus()
        eInput.select()
    }
    NumericCellEditor.prototype.focusOut = function () {}
    return NumericCellEditor
}
