import React, { useContext, useMemo, useState } from 'react'
import {
    EuiButton,
    EuiFlexGroup,
    EuiFlexItem,
    EuiForm,
    EuiModal,
    EuiModalBody,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiSelect,
} from '@elastic/eui'
import { IBuilding } from '../InDoor/components/Interface'
import { deleteBuilding } from '../../store/action'
import { AccessTokenCtx } from '../Context'
const DeleteBuildingForm = ({
    buildings,
    closeModal,
    refresh,
}: {
    buildings: IBuilding[]
    closeModal: Function
    refresh: Function
}) => {
    const accessToken = useContext(AccessTokenCtx)
    const options = useMemo(() => {
        return buildings.map((building) => {
            return { value: building.ID, text: building.name }
        })
    }, [buildings])
    const [value, setValue] = useState(options[1].value)
    const [doubleConfirm, setDoubleConfirm] = useState(false)

    const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setValue(parseInt(e.target.value))
    }

    const onFirstConfirm = () => {
        setDoubleConfirm(true)
    }
    const closeSecondConfirm = () => {
        setDoubleConfirm(false)
    }
    const onSecondConfirm = () => {
        deleteBuilding(accessToken, value)?.finally(() => {
            closeSecondConfirm()
            refresh()
            closeModal()
        })
    }
    return (
        <EuiFlexGroup direction={'column'}>
            <EuiFlexItem>
                <EuiSelect
                    id="buildingPicker"
                    options={options}
                    value={value}
                    onChange={(e) => onChange(e)}
                    aria-label="building picker"
                />
            </EuiFlexItem>
            <EuiFlexItem>
                <EuiFlexGroup justifyContent={'flexEnd'}>
                    <EuiFlexItem>
                        <EuiButton
                            onClick={() => {
                                closeModal()
                            }}
                            color={'text'}
                        >
                            Cancel
                        </EuiButton>
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <EuiButton onClick={onFirstConfirm}>Confirm</EuiButton>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiFlexItem>
            {doubleConfirm ? (
                <EuiModal onClose={closeSecondConfirm}>
                    <EuiModalHeader>
                        <EuiModalHeaderTitle>
                            <h1>Please Confirm the Deletion</h1>
                        </EuiModalHeaderTitle>
                    </EuiModalHeader>
                    <EuiModalBody>
                        <EuiFlexGroup justifyContent={'flexEnd'}>
                            <EuiFlexItem>
                                <EuiButton
                                    onClick={closeSecondConfirm}
                                    color={'text'}
                                >
                                    Cancel
                                </EuiButton>
                            </EuiFlexItem>
                            <EuiFlexItem>
                                <EuiButton onClick={onSecondConfirm}>
                                    Confirm
                                </EuiButton>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </EuiModalBody>
                </EuiModal>
            ) : (
                <></>
            )}
        </EuiFlexGroup>
    )
}

export default DeleteBuildingForm
