export const getDefaultLines = (t: any) => [
    {
        selector: [
            { key: 'temperature', label: t('Temperature'), unit: '[°C]' },
            { key: 'humidity', label: t('Humidity'), unit: '[%]' },
        ],
        title: `${t('Temperature')} / ${t('Humidity')}`,
    },
    {
        selector: [{ key: 'dewpoint', label: t('Dewpoint'), unit: '[ppb]' }],
        title: t('Dewpoint'),
    },
    {
        selector: [{ key: 'vdd', label: t('VDD'), unit: '' }],
        title: t('VDD'),
    },
    {
        selector: [
            { key: 'motion', label: t('Motion'), unit: '' },
            { key: 'light', label: t('Light'), unit: '[lux]' },
        ],
        title: `${t('Motion')} / ${t('Light')}`,
    },
    {
        selector: [{ key: 'co2', label: t('CO2'), unit: '[ppm]' }],
        title: t('CO2'),
    },
]

export const getPrototypeLines = (t: any) => [
    {
        selector: [
            { key: 'temperature', label: t('Temperature'), unit: '[°C]' },
            { key: 'humidity', label: t('Humidity'), unit: '[%]' },
        ],
        title: `${t('Temperature')} / ${t('Humidity')}`,
    },
    {
        selector: [{ key: 'co2', label: t('CO2'), unit: '[ppm]' }],
        title: t('CO2'),
    },
    {
        selector: [{ key: 'tvoc', label: t('TVOC'), unit: '[ppb]' }],
        title: t('TVOC'),
    },
    {
        selector: [{ key: 'pm2dot5', label: t('PM 2.5'), unit: '' }],
        title: t('PM 2.5'),
    },
    {
        selector: [{ key: 'pm10', label: t('PM 10.0'), unit: '' }],
        title: t('PM 10.0'),
    },
]
export const getPrototype2Lines = (t: any) => [
    {
        selector: [
            { key: 'temperature', label: t('Temperature'), unit: '[°C]' },
        ],
        title: `${t('Temperature')} `,
    },
    {
        selector: [{ key: 'humidity', label: t('Humidity'), unit: '[%]' }],
        title: t('Humidity'),
    },
]
export const getTekelekLines = (t: any) => [
    {
        selector: [{ key: 'ullage', label: t('Ullage'), unit: '' }],
        title: t('Ullage'),
    },
]

export const getLinesBySensorType = (t: any, sensorType: string) => {
    switch (sensorType) {
        case 'tekelek':
            return getTekelekLines(t)
        case 'proto':
            return getPrototypeLines(t)
        case 'proto2':
            return getPrototype2Lines(t)
        default:
            return getDefaultLines(t)
    }
}

export const getExportedLines = (t: any, sensorType: string) => {
    return getLinesBySensorType(t, sensorType).reduce<string[]>(
        (acc, line) => {
            line.selector.forEach((selector) => {
                acc.push(selector.key)
            })
            return acc
        },
        ['CreatedAt']
    )
}
