import React, { useEffect, useState } from 'react'
import { GaugeChart } from 'bizcharts'
import { Data, Datum } from 'bizcharts/lib/interface'
import { ListItem } from 'bizcharts/lib/plots/core/dependents'
import styled from 'styled-components'
import { DefaultColor, dropShaw, Gray, Green } from '../../theme/theme'
import { isInt } from '../../utils/number'

const Gauge = ({
    additionalInfo,
    maxValue,
    percent,
    title,
    unit,
    minValue = 0,
    min = 0,
    max = 1,
    width = 300,
    height = 200,
    gap = 0.01,
    colors = [DefaultColor],
    thresholds = [0, 1],
}: {
    additionalInfo?: { value: string; hint: string }
    maxValue: number
    percent: number
    title: string
    unit: string
    minValue?: number
    min?: number
    max?: number
    width?: number
    colors?: string[]
    thresholds?: number[]
    gap?: number
    height?: number
}) => {
    const [matcherMini] = useState(window.matchMedia('(max-height: 768px)'))
    const [matcherMedium] = useState(window.matchMedia('(max-height: 1080px)'))
    const [mini, setMini] = useState(matcherMini.matches)
    const [medium, setMedium] = useState(matcherMedium.matches)

    useEffect(() => {
        matcherMini.addEventListener('change', (ev) => {
            setMini(ev.matches)
        })
        matcherMedium.addEventListener('change', (ev) => {
            setMedium(ev.matches)
        })
        return () => {
            matcherMini.removeEventListener('change', (ev) => {
                setMini(ev.matches)
            })
            matcherMedium.removeEventListener('change', (ev) => {
                setMedium(ev.matches)
            })
        }
    })
    return (
        <Container height={height - 30}>
            <GaugeChart
                autoFit
                width={width - 30}
                height={height - 30}
                percent={percent}
                title={
                    <Title multi={!!additionalInfo}>
                        <div>{title}</div>
                        {additionalInfo && (
                            <div
                                title={additionalInfo.hint}
                                style={{ color: Green, cursor: 'help' }}
                            >
                                {additionalInfo.value}
                            </div>
                        )}
                    </Title>
                }
                axis={{
                    label: {
                        formatter: (
                            text: string,
                            item: ListItem,
                            index: number
                        ) => {
                            return (
                                item.value * (maxValue - minValue) +
                                minValue
                            ).toFixed(0)
                        },
                        style: {
                            fontSize: mini ? 8 : medium ? 12 : 16,
                            opacity: mini ? 0 : 1,
                        },
                    },
                    tickLine: { ...(mini ? { length: 0 } : {}) },
                    subTickLine: { ...(mini ? { length: 0 } : {}) },
                }}
                radius={1}
                innerRadius={0.8}
                indicator={{
                    pin: { style: { opacity: 0 } },
                    pointer: { style: { opacity: 0 } },
                }}
                startAngle={Math.PI * -1.05}
                endAngle={Math.PI * 0.05}
                range={getRangeWithColorV2(percent, colors, thresholds)}
                statistic={{
                    content: {
                        style: {
                            color: '#39B8FF',
                            fontSize: mini ? '12px' : medium ? '20px' : '30px',
                            zIndex: '-1',
                        },
                        formatter: (
                            datum?: Datum,
                            data?: Data /** filterData */
                        ) => {
                            const v =
                                (datum?.percent ?? 0) * (maxValue - minValue) +
                                minValue
                            return (
                                (datum?.percent ?? 0) * (maxValue - minValue) +
                                minValue
                            ).toFixed(isInt(v) ? 0 : 1)
                        },
                        offsetY: -10,
                    },
                    title: {
                        style: {
                            color: '#39B8FF',
                            fontSize: mini ? '6px' : medium ? '8px' : '10px',
                            zIndex: '-1',
                        },
                        formatter: () => unit,
                    },
                }}
            />
        </Container>
    )
}

const getRangeWithColorV2 = (
    cur: number,
    colors: string[] = [DefaultColor],
    thresholds: number[] = [0, 1],
    min: number = 0,
    max: number = 1,
    gap: number = 0.005
) => {
    const block = thresholds.length - 1
    let idx = 0
    const tickColors: string[] = []
    const ticks: number[] = []
    for (let i = 1; i < thresholds.length; i++) {
        if (thresholds[i] > cur) {
            idx = i - 1
            break
        }
    }
    for (let i = 0; i < thresholds.length; i++) {
        ticks.push(thresholds[i])
        if (i !== 0 && i !== block && i !== idx) {
            ticks.push(thresholds[i] + gap)
            tickColors.push(colors[i])
        }
        if (i === idx && idx !== 0) {
            ticks.push(thresholds[i] + gap)
            tickColors.push(DefaultColor)
        }
        if (i < idx) {
            tickColors.push(colors[idx])
        } else if (i === idx) {
            if (cur > thresholds[i] + gap || cur < thresholds[i] - gap) {
                ticks.push(cur)
                tickColors.push(colors[idx])
            }

            tickColors.push(Gray)
        } else {
            tickColors.push(Gray)
        }
    }
    return {
        ticks,
        color: tickColors,
    }
}

export default Gauge
const Container = styled.div<{ height: number }>`
    ${dropShaw(10)};
    margin: 5px;
    padding: 10px;
    height: ${({ height }) => height}px;
    box-sizing: content-box;
`
const Title = styled.div<{ multi: boolean }>`
    display: flex;
    justify-content: ${({ multi }) => (multi ? 'space-evenly' : 'center')};
    line-height: 20px;
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    color: #43bcff;
    @media (max-height: 1080px) {
        font-weight: 500;
        font-size: 18px;
    }
    @media (max-height: 768px) {
        font-weight: 500;
        font-size: 12px;
    }
`
