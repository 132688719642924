import React, { ReactChild, ReactChildren } from 'react'
import styled from 'styled-components'
import {
    calcMarkerMarkerStyle,
    CssMarkerBorderSize,
    defaultV,
    getMarkerStyle,
    K_HINT_HTML_DEFAULT_Z_INDEX,
} from './markerConfig'
interface CssMarkerProps {
    dead: boolean
    isHover: boolean
    width: number
    height: number
    scale: number
    children?: ReactChild | ReactChildren
}

const CssMarker = ({
    dead,
    isHover,
    width,
    height,
    children,
    scale,
}: CssMarkerProps) => {
    const zIndexStyle = {
        zIndex:
            Math.round(scale * 10000) +
            (isHover ? K_HINT_HTML_DEFAULT_Z_INDEX : 0),
    }
    const markerStyle = getMarkerStyle(defaultV.size, defaultV.origin)

    const styleMarkerMarker = calcMarkerMarkerStyle(
        scale,
        zIndexStyle,
        markerStyle
    )

    const markerColor = markerColorPicker(dead)
    return (
        <Wrapper style={{ ...styleMarkerMarker, width, height }}>
            <Foot
                style={{
                    // here we force the width to be height
                    width: height,
                    height,
                    backgroundColor: markerColor,
                    left: (width - height) / 2,
                    top: CssMarkerBorderSize,
                }}
            />
            <Content
                style={{
                    width,
                    height,
                    borderColor: markerColor,
                    boxSizing: 'content-box',
                    border: `${CssMarkerBorderSize}px solid ${markerColor}`,
                }}
            >
                {children}
            </Content>
        </Wrapper>
    )
}

export default CssMarker

const markerColorPicker = (dead: boolean) => {
    if (dead) {
        return 'red'
    } else {
        return '#00cae9'
    }
}
const Wrapper = styled.div`
    position: relative;
    z-index: 0;
`
const Content = styled.div`
    background-color: #fff;
    border-radius: 15px;
`
const Foot = styled.div`
    border-radius: 50% 50% 50% 0;
    position: absolute;
    transform: rotate(-45deg);
    top: ${CssMarkerBorderSize}px;
    z-index: -1;
`
