import React, { useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import LoadingBar from 'react-top-loading-bar'

import moment from 'moment'
import {
    EMode,
    IChirpstackDeviceMeasures,
    IData,
    ILine,
} from '../../intefaces/measure'
import { DefaultColor, headerHeight, sideBarWidth } from '../../theme/theme'
import DashLineChart from '../DashboardUnits/DashLineChart'
import { AccessTokenCtx } from '../Context'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import DatePickers from '../DashboardUnits/DatePicker'
import DownloadSVG from '../../assets/download.svg'
import SideBarChirpstack from '../SideBarChirpstack'
import { getExportedLines, getLinesBySensorType } from './getLines'
import { IGeolocation } from '../../intefaces/map'
import { exportDataToXlsIoT } from '../../utils/export'

export interface IField {
    key: string
    label: string
    unit: string
}

const getDashboardSizing = () => {
    const totalHeight = window.innerHeight - headerHeight - 40 - 45
    const totalWidth = window.innerWidth - sideBarWidth - 60
    const dividedBy = window.innerHeight < 1080 ? 4 : 5
    const partialHeight = Math.floor(totalHeight / dividedBy)
    const gaugeWidth = partialHeight * 1.4
    const chartWidth = totalWidth //  - gaugeWidth * 2
    const verticalGaugeHeight = partialHeight * (dividedBy - 2)
    return { gaugeWidth, partialHeight, chartWidth, verticalGaugeHeight }
}
// fetch every 1 min
const interval = 60 * 1000

const Dashboard = () => {
    const { t } = useTranslation()
    const accessToken = useContext(AccessTokenCtx)
    const { path } = useRouteMatch()
    const history = useHistory()
    const { deviceId } = useParams<{ deviceId: string }>()
    const [data, setData] = useState<IChirpstackDeviceMeasures[]>([])
    const [lines, setLines] = useState<{ selector: ILine[]; title: string }[]>(
        []
    )
    const [sizing, setSizing] = useState(getDashboardSizing())
    const [selectedSensor, setSelectedSensor] = useState<IGeolocation>({
        deviceId,
    } as IGeolocation)
    const [fetchInterval, setFetchInterval] = useState<NodeJS.Timeout>()
    const [mode, setMode] = useState<EMode>(EMode.Auto)
    const [exporting, setExporting] = useState(false)
    const [relativeDate, setRelativeDate] = useState<[Date, Date]>([
        new Date(),
        new Date(),
    ])
    const ref = useRef<any>(null)
    const clearFetch = () => {
        if (fetchInterval !== undefined) {
            clearInterval(fetchInterval)
        }
    }
    useEffect(() => {
        window.addEventListener('resize', () => {
            setSizing(getDashboardSizing())
        })
        return () => {
            window.removeEventListener('resize', () => {
                setSizing(getDashboardSizing())
            })
        }
    }, [])
    useEffect(() => {
        if (mode === EMode.Relative) {
            fetchData(...relativeDate)
        }
    }, [mode, relativeDate, selectedSensor])
    useEffect(() => {
        clearFetch()
        if (selectedSensor?.deviceId) {
            if (mode === EMode.Auto) {
                fetchData()
                setFetchInterval(
                    setInterval(() => {
                        fetchData()
                    }, interval)
                )
                return () => {
                    if (fetchInterval !== undefined) {
                        clearInterval(fetchInterval)
                    }
                }
            }
        }
    }, [selectedSensor, mode])
    const fetchData = (start?: Date, end?: Date) => {
        if (!selectedSensor?.deviceId) {
            return
        }
        try {
            ref?.current?.continuousStart(10, 100)
            fetch(
                `/api/v1/chirpstack/measures?start=${
                    start
                        ? moment(start).unix()
                        : moment().add(-3, 'days').unix()
                }&end=${end ? moment(end).unix() : ''}&deviceSerialNumber=${
                    selectedSensor.deviceId
                }`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            )
                .then((res) => res.json())
                .then((data) => {
                    ref?.current?.complete()

                    setData(data)
                })
        } catch (e) {
            console.error(e)
        }
    }
    const onSensorSelected = (id: string) => {
        history.push(path.replace('/:deviceId', '') + '/' + id)
    }

    const dataSelector = (
        data: IChirpstackDeviceMeasures[],
        source: IField[]
    ) => {
        const sortedData = data?.sort(
            (a, b) => moment(b.CreatedAt).unix() - moment(a.CreatedAt).unix()
        )
        return source.reduce((acc: IData[], cur) => {
            sortedData?.forEach((mv) => {
                const y = mv[cur.key as keyof IChirpstackDeviceMeasures]
                if (y !== undefined) {
                    acc.push({
                        x: moment(mv.CreatedAt).unix(),
                        y,
                        tooltipV: moment(mv.CreatedAt).format(
                            'YYYY/MM/DD HH:mm:ss'
                        ),
                        type: `${t(cur.label)} ${cur.unit}`,
                    } as unknown as IData)
                }
            })
            return acc
        }, [])
    }

    useEffect(() => {
        setLines(
            getLinesBySensorType(t, selectedSensor.sensorType).map((line) => ({
                selector: dataSelector(data, line.selector),
                title: line.title,
            }))
        )
    }, [data, selectedSensor.sensorType, t])
    return (
        <>
            <LoadingBar progress={0} color={DefaultColor} ref={ref} />
            <SideBarChirpstack
                onSensorSelected={onSensorSelected}
                selectedSensor={selectedSensor}
                setSelectedSensor={setSelectedSensor}
            />
            <Container>
                <FlexRow style={{ justifyContent: 'space-between' }}>
                    <DatePickers
                        mode={mode}
                        onAutoMode={() => {
                            setMode(EMode.Auto)
                        }}
                        onRelativeMode={(date: [Date, Date]) => {
                            setMode(EMode.Relative)
                            setRelativeDate(date)
                        }}
                        onRelativeDateChange={(date: [Date, Date]) => {
                            setRelativeDate(date)
                        }}
                    />
                    <DownloadWrapper
                        title={t('Download Sensor Data')}
                        onClick={() => {
                            if (!exporting && selectedSensor.deviceId) {
                                exportDataToXlsIoT(
                                    data,
                                    selectedSensor.deviceName,
                                    getExportedLines(
                                        t,
                                        selectedSensor.sensorType
                                    )
                                )
                            }
                        }}
                    >
                        <DownloadImg src={DownloadSVG} />
                    </DownloadWrapper>
                </FlexRow>
                {lines.map((line, index) => (
                    <FlexRow key={line.title}>
                        <DashLineChart
                            title={line.title}
                            width={sizing.chartWidth}
                            height={sizing.partialHeight}
                            data={line.selector}
                        />
                    </FlexRow>
                ))}
            </Container>
        </>
    )
}

export default Dashboard

const Container = styled.div`
    position: absolute;
    width: calc(100vw - 251px);
    height: calc(100vh - 90px);
    position: absolute;
    right: 0;
    top: 70px;
    overflow: auto;
`
const FlexRow = styled.div`
    display: flex;
    margin: 5px 0;
`
const DownloadWrapper = styled.div`
    margin: 5px 65px 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`
const DownloadImg = styled.img`
    width: 30px;
`
