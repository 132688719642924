import React, { useContext } from 'react'
import ReportSelector from './ReportSelector'
import ReportBody from './ReportBody'
import { AccessTokenCtx } from '../Context'
import { IReportData } from '../../intefaces/measure'

const Report = () => {
    const accessToken = useContext(AccessTokenCtx)
    const [data, setData] = React.useState<IReportData>({
        week: 0,
        year: 0,
        from: '',
        to: '',
        co2SeriesData: [],
    })
    const fetchReportData = async (data: {
        week: number
        year: number
        identifier: string
        platform: string
        device_type: string
    }) => {
        const params = new URLSearchParams()
        Object.keys(data).forEach((key) => {
            // @ts-ignore
            params.append(key, data?.[key]?.toString() ?? '')
        })
        try {
            const result = await fetch('/api/v1/report?' + params.toString(), {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'content-type': 'application/json',
                },
                method: 'GET',
            })
            const data = await result.json()
            setData(data)
        } catch (e) {
            console.error(e)
        }
    }
    return (
        <>
            <ReportSelector fetchReportData={fetchReportData} />
            <ReportBody data={data} />
        </>
    )
}

export default Report
