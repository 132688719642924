import styled from 'styled-components'
import { dropShaw } from '../../../theme/theme'
import { IBuilding, IChirpstackDevice, IPlan } from './Interface'
import React, { useContext, useRef, useState } from 'react'
import Plan from '../../../assets/houche2.png'
import { SensorWithData } from './SensorWithData'
import 'moment/locale/fr'
import * as moment from 'moment'
import { updateBuildingIndoorPosition } from '../../../store/action'
import { AccessTokenCtx } from '../../Context'
moment.locale(window?.navigator?.language ?? 'en')

export function GlobalMonitor({
    buildings,
    isAdmin,
    refresh,
}: {
    buildings: IBuilding[]
    isAdmin: boolean
    refresh: Function
}) {
    const accessToken = useContext(AccessTokenCtx)
    const [pickedBuildingId, setPickedBuildingId] = useState(-1)
    const [
        enableBuildingIndoorPositionSetter,
        setEnableBuildingIndoorPositionSetter,
    ] = useState(false)
    const wrapperRef = useRef<HTMLImageElement>(null)
    const getBuildSensors = (plans: IPlan[]) => {
        return plans.reduce((acc, plan) => {
            plan.chirpstackDevices.forEach((device) => {
                acc.push(device)
            })
            return acc
        }, [] as IChirpstackDevice[])
    }
    return (
        <div>
            <Wrapper
                ref={wrapperRef}
                onContextMenu={(e) => {
                    e.preventDefault()
                }}
            >
                <Img
                    id={'plan'}
                    src={Plan}
                    alt={'indoor plan'}
                    onContextMenu={(e) => {
                        e.preventDefault()
                        if (isAdmin) {
                            setEnableBuildingIndoorPositionSetter(
                                !enableBuildingIndoorPositionSetter
                            )
                            if (enableBuildingIndoorPositionSetter) {
                                setPickedBuildingId(-1)
                            }
                        }
                    }}
                    onClick={(ev) => {
                        if (enableBuildingIndoorPositionSetter) {
                            updateBuildingIndoorPosition(accessToken, {
                                ID: pickedBuildingId,
                                indoorX: wrapperRef.current
                                    ? (ev.clientX -
                                          (wrapperRef.current.offsetLeft ??
                                              0)) /
                                      wrapperRef.current.clientWidth
                                    : 0,
                                indoorY: wrapperRef.current
                                    ? (ev.clientY -
                                          (wrapperRef?.current?.offsetTop ??
                                              0)) /
                                      wrapperRef.current.clientHeight
                                    : 0,
                            })?.then(() => {
                                refresh()
                            })
                        }
                    }}
                />
                {buildings?.map(({ ID, indoorX, indoorY, summary, plans }) => (
                    <SensorWithData
                        key={ID}
                        indoorPosition={[indoorX, indoorY]}
                        measure={{
                            temperature: summary.avgTemperature,
                            humidity: summary.avgHumidity,
                            highLowTemperatureAlert:
                                summary.highLowTemperatureAlert,
                        }}
                        parentWidth={wrapperRef?.current?.clientWidth}
                        devices={getBuildSensors(plans)}
                        picked={ID == pickedBuildingId}
                        onClick={() => {
                            if (enableBuildingIndoorPositionSetter) {
                                setPickedBuildingId(ID)
                            }
                        }}
                        onContextMenu={() => {}}
                    />
                ))}
            </Wrapper>
        </div>
    )
}

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    height: calc(100vh - 60px);
    ${dropShaw(10)}
`
const Img = styled.img`
    width: 100%;
    height: 100%;
`
