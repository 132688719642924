import React, { useContext, useState } from 'react'
import { AccessTokenCtx } from '../Context'
import { createBuilding } from '../../store/action'
import {
    EuiButton,
    EuiFieldText,
    EuiForm,
    EuiFormRow,
    EuiSpacer,
} from '@elastic/eui'

export const CreateBuildingForm = ({
    closeModal,
    refresh,
}: {
    closeModal: Function
    refresh: Function
}) => {
    const accessToken = useContext(AccessTokenCtx)
    const [formData, setFormData] = useState({
        name: '',
        group: '',
    })
    const [formStatus, setFormStatus] = useState({
        loading: false,
        validation: {
            name: false,
            group: false,
        },
        invalidated: false,
        validationErrors: new Array<string>(),
    })

    const onChange = (e: any) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }
    const submit = () => {
        const validationStatus = validate()
        if (validationStatus.invalidated) {
            setFormStatus({ ...validationStatus })
        }
        setFormStatus({ ...validationStatus, loading: true })
        createBuilding(
            accessToken,
            { ...formData },
            ({ success }: { success: boolean }) => {
                setFormStatus({ ...validationStatus, loading: false })
                if (success) {
                    refresh()
                    closeModal()
                }
            }
        )
    }

    const validate = () => {
        const status = {
            ...formStatus,
            validation: {
                name: false,
                group: false,
            },
            invalidated: false,
            validationErrors: new Array<string>(),
        }
        if (formData.name === '') {
            status.validation.name = true
            status.invalidated = true
            status.validationErrors.push('Name is required')
        }
        if (formData.group === '') {
            status.validation.group = true
            status.invalidated = true
            status.validationErrors.push('Group is required')
        }
        return status
    }
    return (
        <>
            <EuiForm
                id="createBuilding"
                component="div"
                isInvalid={formStatus.invalidated}
                error={formStatus.validationErrors}
            >
                <EuiFormRow
                    label="Building Name"
                    isInvalid={formStatus.validation.name}
                >
                    <EuiFieldText name="name" onChange={onChange} />
                </EuiFormRow>
                <EuiFormRow
                    label="Access Group"
                    isInvalid={formStatus.validation.group}
                >
                    <EuiFieldText name="group" onChange={onChange} />
                </EuiFormRow>
            </EuiForm>
            <EuiSpacer />
            <EuiButton onClick={submit} isLoading={formStatus.loading}>
                Create
            </EuiButton>
        </>
    )
}
