import React, { useRef } from 'react'
import styled from 'styled-components'

const PlanManager = () => {
    const fileUploader = useRef<HTMLInputElement>(null)
    const fileName = useRef<HTMLInputElement>(null)

    return (
        <Wrapper>
            <div>Plan File</div>
            <input ref={fileUploader} type={'file'} id={'plan_uploader'} />
            <div>Plan Name:</div>
            <input ref={fileName} type={'text'} id={'plan_name'} />
        </Wrapper>
    )
}

export default PlanManager

const Wrapper = styled.div``
