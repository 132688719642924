import { IBuilding } from '../InDoor/components/Interface'
import React, { useContext, useMemo, useState } from 'react'
import { AccessTokenCtx } from '../Context'
import { createPlan } from '../../store/action'
import {
    EuiButton,
    EuiFieldText,
    EuiForm,
    EuiFormRow,
    EuiSelect,
    EuiSpacer,
} from '@elastic/eui'

export const CreatePlanForm = ({
    buildings,
    closeModal,
    refresh,
}: {
    buildings: IBuilding[]
    closeModal: Function
    refresh: Function
}) => {
    const accessToken = useContext(AccessTokenCtx)
    const buildingOptions = useMemo(
        () =>
            buildings.map((building) => {
                return {
                    value: building.ID,
                    text: building.name,
                }
            }),
        [buildings]
    )
    const [buildingOption, setBuildingOption] = useState(
        buildingOptions[0].value
    )
    const [planName, setPlanName] = useState('')
    const [formStatus, setFormStatus] = useState({
        loading: false,
        validation: {
            name: false,
            building: false,
        },
        invalidated: false,
        validationErrors: new Array<string>(),
    })

    const onPickBuilding = (e: any) => {
        setBuildingOption(parseInt(e.target.value))
    }
    const onPlanNameChange = (e: any) => {
        setPlanName(e.target.value)
    }
    const validate = () => {
        const status = {
            ...formStatus,
            validation: {
                name: false,
                building: false,
            },
            invalidated: false,
            validationErrors: new Array<string>(),
        }
        if (planName.length === 0) {
            status.validationErrors.push('Plan name is required')
            status.validation.name = true
            status.invalidated = true
        }
        if (buildingOption <= 0) {
            status.validationErrors.push('Building is required')
            status.validation.building = true
            status.invalidated = true
        }

        return status
    }
    const submit = () => {
        const status = validate()
        if (status.invalidated) {
            setFormStatus({ ...status })
        }
        setFormStatus({ ...status, loading: true })
        if (status.invalidated) {
            return
        }
        createPlan(
            accessToken,
            {
                name: planName,
                buildingId: buildingOption,
            },
            ({ success }: { success: boolean }) => {
                setFormStatus({ ...formStatus, loading: false })
                if (success) {
                    refresh()
                    closeModal()
                }
            }
        )
    }
    return (
        <>
            <EuiForm
                id="createPlan"
                component="div"
                isInvalid={formStatus.invalidated}
                error={formStatus.validationErrors}
            >
                <EuiFormRow
                    label="Plan Name"
                    isInvalid={formStatus.validation.name}
                >
                    <EuiFieldText name="name" onChange={onPlanNameChange} />
                </EuiFormRow>
                <EuiFormRow
                    label="Pick Building"
                    isInvalid={formStatus.validation.building}
                >
                    <EuiSelect
                        id="selectBuilding"
                        options={buildingOptions}
                        value={buildingOption}
                        onChange={(e) => onPickBuilding(e)}
                        aria-label="Use aria labels when no actual label is in use"
                    />
                </EuiFormRow>
            </EuiForm>
            <EuiSpacer />
            <EuiButton onClick={submit}>Create</EuiButton>
        </>
    )
}
