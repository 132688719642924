import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { IChirpstackDevice, ISensorMeasure } from './Interface'
import SensorWidget from './SensorWidget'
import {
    gradientBlue,
    gradientGreen,
    gradientOrange,
    gradientRed,
} from '../../../theme/theme'
import SensorDetails from './SensorDetails'
import { getTemperaturePercentage } from './utils'

function useMoveInOut() {
    const [hideTimeout, setHideTimeout] = useState<NodeJS.Timeout>()
    const [hovered, setHovered] = useState<boolean>(false)
    const [delayedHovered, setDelayedHovered] = useState<boolean>(false)

    useEffect(() => {
        if (hovered) {
            if (hideTimeout) {
                clearTimeout(hideTimeout)
            }
            setDelayedHovered(true)
        } else {
            setHideTimeout(
                setTimeout(() => {
                    setDelayedHovered(false)
                }, 200)
            )
        }
    }, [hovered])

    return [delayedHovered, setHovered] as const
}
export const SensorWithData = ({
    indoorPosition,
    measure,
    picked,
    onContextMenu,
    devices,
    parentWidth,
    onClick,
}: {
    measure: ISensorMeasure
    indoorPosition: number[]
    picked: boolean
    onContextMenu: Function
    // for global mode, we show devices as additional info
    devices?: IChirpstackDevice[]
    parentWidth?: number
    onClick?: Function
}) => {
    const [hovered, setHovered] = useMoveInOut()
    const getBackGroundColor = (temperature: number) => {
        if (measure.highLowTemperatureAlert) {
            return gradientRed
        }
        if (temperature < 17) {
            return gradientBlue
        } else if (temperature > 23) {
            return gradientOrange
        }
        return gradientGreen
    }
    return (
        <SensorData
            indoorPosition={indoorPosition}
            picked={picked}
            onClick={() => {
                onClick?.()
            }}
            onContextMenu={(e) => {
                e.preventDefault()
                onContextMenu()
            }}
            onMouseEnter={(e) => {
                setHovered(true)
            }}
            onMouseLeave={(e) => {
                setHovered(false)
            }}
            backgroundColor={getBackGroundColor(measure.temperature)}
        >
            <SensorWidget
                size={35}
                labelLeft={(measure?.temperature ?? 0).toFixed(1)}
                labelRight={(measure?.humidity ?? 0)?.toFixed(1)}
                unitLeft={'°C'}
                unitRight={'%'}
                percentageLeft={getTemperaturePercentage(
                    measure?.temperature ?? 0
                )}
                percentageRight={measure?.humidity}
            />
            {(devices?.length ?? 0) > 0 ? (
                <SensorDetails
                    hovered={hovered}
                    devices={devices}
                    parentWidth={parentWidth}
                    positions={indoorPosition}
                    setHovered={setHovered}
                />
            ) : (
                <></>
            )}

            {/*<DataLayer>*/}
            {/*    <SensorDataColumn>*/}
            {/*        <SensorTemperature>*/}
            {/*            {measure?.temperature?.toFixed(1) || 0}°C*/}
            {/*        </SensorTemperature>*/}
            {/*        <SensorHumidity>*/}
            {/*            {measure?.humidity?.toFixed(1) || 0}%*/}
            {/*        </SensorHumidity>*/}
            {/*    </SensorDataColumn>*/}
            {/*    <SensorImg src={Sensor} alt={'sensor'} />*/}
            {/*</DataLayer>*/}
            {/*<ArrowDown />*/}
        </SensorData>
    )
}

// We need to make a minimum position for the sensor, otherwise it will be hidden
const SensorData = styled.div<{
    indoorPosition: number[]
    picked: boolean
    backgroundColor: string
}>`
    cursor: default;
    position: absolute;
    width: 102px;
    height: 73px;
    left: calc(
        ${({ indoorPosition }) => Math.max(0.03, indoorPosition[0]) * 100}% -
            50px
    );
    top: calc(
        ${({ indoorPosition }) => Math.max(0.05, indoorPosition[1]) * 100}% -
            60px
    );
    display: grid;
    grid-template-rows: 1fr 1fr;
    place-items: center;
    background: ${({ picked, backgroundColor }) =>
        picked ? gradientOrange : backgroundColor};
    font-size: 16px;
    font-weight: bold;
    box-shadow: 0px 1px 10px rgb(0 0 0 / 25%);
    border-radius: 50px;
`
const DataLayer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
`
const SensorDataColumn = styled.div`
    display: grid;
    grid-template-rows: 1fr 1fr;
    place-items: center;
`
const SensorTemperature = styled.div``
const SensorHumidity = styled.div``
const SensorImg = styled.img`
    width: 50px;
    height: 50px;
`
const ArrowDown = styled.div`
    width: 0px;
    height: 0px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #4bdbba;
`
