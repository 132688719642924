import styled from 'styled-components'
import { dropShaw } from '../../../theme/theme'
import { IPlan } from './Interface'
import PlanManager from './PlanManager'
import { PlanWithSensor } from './PlanWithSensor'
import React, { useState } from 'react'

export function PlanMonitor({
    isAdmin,
    pickedPlanId,
    pickedSensorId,
    setPickedSensorId,
    setPickedPlanId,
    updateSensorIndoorPosition,
    plans,
}: {
    isAdmin: boolean
    pickedPlanId: number
    pickedSensorId: number
    setPickedSensorId: (id: number) => void
    setPickedPlanId: (id: number) => void
    updateSensorIndoorPosition: Function
    plans: IPlan[]
}) {
    const [tooltipDisplay, setTooltipDisplay] = useState(false)
    return (
        <div>
            <Tooltip visible={tooltipDisplay}>
                <SelectorGroup>
                    <div>Picked Plan</div>
                    <PlanSelector
                        value={pickedPlanId}
                        onChange={(e) =>
                            setPickedPlanId(parseInt(e.target.value))
                        }
                    >
                        <PlanOption value={0} />
                        {plans?.map(({ ID, name }) => (
                            <PlanOption key={ID} value={ID}>
                                {name}
                            </PlanOption>
                        ))}
                    </PlanSelector>
                </SelectorGroup>
                <SelectorGroup>
                    <div>Picked Sensor</div>
                    <SensorSelector
                        value={pickedSensorId}
                        onChange={(e) =>
                            setPickedSensorId(parseInt(e.target.value))
                        }
                    >
                        <SensorOption value={0} />
                        {plans
                            ?.find((p) => p.ID === pickedPlanId)
                            ?.chirpstackDevices?.map(({ ID, deviceName }) => (
                                <SensorOption key={ID} value={ID}>
                                    {deviceName}
                                </SensorOption>
                            ))}
                    </SensorSelector>
                </SelectorGroup>
                <PlanManager />
            </Tooltip>
            {plans.map(({ ID, name, chirpstackDevices }) => (
                <PlanWithSensor
                    key={ID}
                    planName={name}
                    planId={ID}
                    pickedSensor={pickedSensorId}
                    setPickedSensor={(id: number) => {
                        if (!isAdmin) {
                            return
                        }
                        setPickedSensorId(id)
                    }}
                    sensors={chirpstackDevices}
                    updateSensorIndoorPosition={updateSensorIndoorPosition(ID)}
                    onContextMenu={() => {
                        if (!isAdmin) {
                            return
                        }
                        setTooltipDisplay(!tooltipDisplay)
                    }}
                />
            ))}
        </div>
    )
}

const Tooltip = styled.div<{ visible: boolean }>`
    display: ${({ visible }) => (visible ? 'grid' : 'none')};
    grid-template-columns: 350px;
    grid-template-rows: 1fr 1fr 1fr;
    place-items: center;
    position: fixed;
    z-index: 1000;
    background: white;
    height: 280px;
    
   ${dropShaw(10)}
}
`
const SelectorGroup = styled.div`
    margin: 5px;
    width: 250px;
    font-size: 18px;
`
const SensorSelector = styled.select`
    width: 250px;
    height: 25px;
    line-height: 25px;
    border: 0px;
    ${dropShaw(10)}
`
const SensorOption = styled.option``
const PlanSelector = styled.select`
    width: 250px;
    height: 25px;
    border: 0px;
    ${dropShaw(10)}
`
const PlanOption = styled.option``
