import { Button } from '../../ui/Button'
import Modal from './Modal'
import React, { ReactChild, ReactChildren, ReactNode, useState } from 'react'

const ButtonWithModal = ({
    title,
    buttonText,
    children,
}: {
    title: string
    buttonText: string
    children: Function
}) => {
    const [isModalVisible, setIsModalVisible] = useState(false)
    const closeModal = () => {
        setIsModalVisible(false)
    }
    const showModal = () => setIsModalVisible(true)
    return (
        <>
            <Button style={{ margin: '0 10px' }} onClick={showModal}>
                {buttonText}
            </Button>
            {isModalVisible && (
                <Modal title={title} closeModal={closeModal}>
                    {children}
                </Modal>
            )}
        </>
    )
}
export default ButtonWithModal
