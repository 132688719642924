import React, { useContext, useState } from 'react'
import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import { CellClickedEvent, ColumnApi, GridApi } from 'ag-grid-community'
import * as moment from 'moment'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import {
    CellDoubleClickedEvent,
    GridReadyEvent,
} from 'ag-grid-community/dist/lib/events'
import ChevronDown from '../assets/chevron-down.svg'
import ChevronUp from '../assets/chevron-up.svg'
import styled from 'styled-components'
import { Button } from '../ui/Button'
import { ILastMeasure } from '../intefaces/measure'
import { ValueFormatterParams } from 'ag-grid-community/dist/lib/entities/colDef'

import 'moment/locale/fr'
import { AccessTokenCtx } from './Context'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

moment.locale(window?.navigator?.language ?? 'en')

const Grid = ({
    data,
    onHoverId,
}: {
    data: ILastMeasure[]
    onHoverId: Function
}) => {
    const { t, i18n } = useTranslation()
    const history = useHistory()
    const accessToken = useContext(AccessTokenCtx)
    const [display, setDisplay] = useState<string>('0')
    const [gridApi, setGridApi] = useState<GridApi>()
    const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>()

    const resizeGrid = () => {
        gridColumnApi?.autoSizeAllColumns()
    }
    const onGridReady = (e: GridReadyEvent) => {
        setGridApi(e.api)
        setGridColumnApi(e.columnApi)
        e.columnApi.autoSizeAllColumns()
    }
    const showData = () => {
        setDisplay(display === '0' ? '500px' : '0')
    }
    const isHidden = display === '0'
    const buttonString = t(`${isHidden ? 'Show' : 'Hide'} Data Table`)
    return (
        <div>
            <ShowDataButtonWrapper bottom={display}>
                <Button onClick={showData} large={i18n.language === 'fr'}>
                    <ChevronWrapper src={isHidden ? ChevronUp : ChevronDown} />
                    {buttonString}
                </Button>
            </ShowDataButtonWrapper>

            <GridWrapper>
                <AgGridWrapper className="ag-theme-alpine" display={display}>
                    <AgGridReact
                        onGridReady={onGridReady}
                        rowData={data}
                        onRowDataChanged={() => {
                            resizeGrid()
                        }}
                    >
                        <AgGridColumn
                            headerName={t('id')}
                            field="name"
                            resizable
                            filter
                            sortable
                            pinned
                            sort={'asc'}
                            onCellClicked={(event: CellClickedEvent) => {
                                history.push(
                                    `/${i18n.language}/dashboard/${event.data.id}`
                                )
                            }}
                        />
                        <AgGridColumn
                            headerName={t('Location')}
                            field="location"
                            resizable
                            filter
                            sortable
                        />
                        <AgGridColumn
                            headerName={t('Time')}
                            field="time"
                            valueFormatter={(params: ValueFormatterParams) => {
                                return moment.unix(params.value).fromNow()
                            }}
                            resizable
                            filter
                            sortable
                        />
                        <AgGridColumn
                            headerName={t('Temperature') + '(°C)'}
                            field="temperature"
                            resizable
                            filter
                            sortable
                        />
                        <AgGridColumn
                            headerName={t('Humidity') + '(HR%)'}
                            field="humidity"
                            resizable
                            filter
                            sortable
                        />
                        <AgGridColumn
                            headerName={t('CO2') + '(ppm)'}
                            field="co2"
                            resizable
                            filter
                            sortable
                        />
                        <AgGridColumn
                            headerName={t('TVOC') + '(ppb)'}
                            valueGetter={(params) => {
                                // TODO: clean this when fully remove nemo
                                return params.data.tvoc || params.data.lvoc
                            }}
                            resizable
                            filter
                            sortable
                        />

                        <AgGridColumn
                            headerName={t('PM 2.5') + '(µg/m³)'}
                            field="pm2dot5"
                            resizable
                            filter
                            sortable
                        />
                        <AgGridColumn
                            headerName={t('PM 10.0') + '(µg/m³)'}
                            field="pm10"
                            resizable
                            filter
                            sortable
                        />
                        <AgGridColumn
                            headerName={t('Pressure') + '(mb)'}
                            field="pressure"
                            resizable
                            filter
                            sortable
                        />
                        <AgGridColumn
                            headerName={t('PM 1.0') + '(µg/m³)'}
                            field="pm1"
                            resizable
                            filter
                            sortable
                            hide
                        />
                        <AgGridColumn
                            headerName={t('Formaldehyde') + '(µg/m³)'}
                            field="formaldehyde"
                            resizable
                            filter
                            sortable
                            hide
                        />
                        <AgGridColumn
                            headerName={t('Battery') + '(v)'}
                            field="battery"
                            resizable
                            filter
                            sortable
                            hide
                        />
                        <AgGridColumn
                            headerName={t('Ullage')}
                            field="ullage"
                            resizable
                            filter
                            sortable
                        />
                    </AgGridReact>
                </AgGridWrapper>
            </GridWrapper>
        </div>
    )
}

export default Grid

const GridWrapper = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
`
const AgGridWrapper = styled.div.attrs<{ display: string }>(({ display }) => ({
    style: { maxHeight: display },
}))<{ display: string }>`
    width: calc(100vw - 251px);
    height: 500px;
    transition: max-height 1s;
`

const ChevronWrapper = styled.img`
    padding-top: 1px;
    margin-right: 10px;
`
const ShowDataButtonWrapper = styled.div.attrs<{ bottom: string }>(
    ({ bottom }) => ({
        style: { bottom: bottom },
    })
)<{ bottom: string }>`
    position: absolute;
    right: calc(50vw - 233px);
    transition: bottom 1s;
`
