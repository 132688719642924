import { IGeolocation } from '../intefaces/map'
import {
    IBuilding,
    IChirpstackDevice,
    IPlan,
} from '../component/InDoor/components/Interface'

export const fetchDevices = (accessToken: string, callback: Function) => {
    try {
        fetch('/api/v3/devices', {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        })
            .then((res) => res.json())
            .then((data) =>
                callback(
                    data.sort((a: IGeolocation, b: IGeolocation) =>
                        a.deviceName < b.deviceName ? -1 : 1
                    )
                )
            )
    } catch (e) {
        console.error(e)
    }
}

export const fetchBuildings = (accessToken: string, callback: Function) => {
    try {
        fetch('/api/v1/indoor/buildings', {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        })
            .then((res) => res.json())
            .then((data) => callback(data))
    } catch (e) {
        console.error(e)
    }
}

export const fetchPlans = (accessToken: string, callback: Function) => {
    try {
        fetch('/api/v1/indoor/plans', {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        })
            .then((res) => res.json())
            .then((data) => callback(data))
    } catch (e) {
        console.error(e)
    }
}

// create building
export const createBuilding = (
    accessToken: string,
    building: Partial<IBuilding>,
    callback: Function
) => {
    try {
        fetch('/api/v1/indoor/building', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(building),
        })
            .then((res) => res.json())
            .then(() => callback({ success: true }))
    } catch (e) {
        callback({ success: false })
        console.error(e)
    }
}

export const createPlan = (
    accessToken: string,
    plan: Partial<IPlan>,
    callback: Function
) => {
    try {
        fetch('/api/v1/indoor/plan', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(plan),
        })
            .then((res) => res.json())
            .then(() => callback({ success: true }))
    } catch (e) {
        callback({ success: false })
        console.error(e)
    }
}

export const updateIndoorDevice = (
    accessToken: string,
    device: Partial<IChirpstackDevice>
) => {
    try {
        return fetch('/api/v1/indoor/device', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(device),
        }).then((res) => res.json())
    } catch (e) {
        console.error(e)
    }
}

export const updateBuildingIndoorPosition = (
    accessToken: string,
    building: Partial<IBuilding>
) => {
    try {
        return fetch('/api/v1/indoor/building/indoor', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(building),
        }).then((res) => res.json())
    } catch (e) {
        console.error(e)
    }
}

export const deletePlan = (accessToken: string, planId: number) => {
    try {
        return fetch('/api/v1/indoor/plan', {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id: planId }),
        }).then((res) => res.json())
    } catch (e) {
        console.error(e)
    }
}
export const deleteBuilding = (accessToken: string, buildingId: number) => {
    try {
        return fetch('/api/v1/indoor/building', {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id: buildingId }),
        }).then((res) => res.json())
    } catch (e) {
        console.error(e)
    }
}
