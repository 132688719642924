import React from 'react'
import styled from 'styled-components'
import { IChirpstackDevice } from './Interface'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const SensorDetails = ({
    hovered,
    devices,
    positions,
    parentWidth,
}: {
    hovered?: boolean
    devices?: IChirpstackDevice[] | undefined
    positions?: number[]
    parentWidth?: number | undefined
    setHovered: Function
}) => {
    const { t } = useTranslation()
    const isOverflow = (parentWidth: number, positionX: number) => {
        return parentWidth * positionX + 50 + 550 > parentWidth
    }
    return (
        <Wrapper
            hovered={hovered}
            isOverflow={isOverflow(parentWidth ?? 0, positions?.[0] ?? 0)}
        >
            <Row>
                <div>{t('Name')}</div>
                <div>{t('Date')}</div>
                <div>{t('Temperature')}</div>
                <div>{t('Humidity')}</div>
            </Row>
            {devices?.map(
                ({
                    ID,
                    name,
                    deviceName,
                    geoLocation,
                    lastMeasure: { temperature, humidity, CreatedAt },
                }) => (
                    <Row key={ID}>
                        <div>{geoLocation?.location || name || deviceName}</div>
                        <div>{moment(CreatedAt).fromNow()}</div>
                        <div>{temperature.toFixed(1)}°C</div>
                        <div>{humidity.toFixed(1)}%</div>
                    </Row>
                )
            )}
        </Wrapper>
    )
}

export default SensorDetails

const Wrapper = styled.div<{ hovered?: boolean; isOverflow: boolean }>`
    position: absolute;
    z-index: ${({ hovered }) => (hovered ? 10000 : -1)};
    top: 0;
    ${({ isOverflow }) => (isOverflow ? 'right: 110px;' : 'left: 110px;')}
    background: white;
    box-shadow: 0px 1px 10px rgb(0 0 0 / 25%);
    display: flex;
    flex-direction: column;
    opacity: ${({ hovered }) => (hovered ? 1 : 0)};
    transition: opacity 0.5s ease;
    max-height: 500px;
    overflow-y: scroll;
`

const Row = styled.div`
    background: white;
    box-shadow: 0px 1px 10px rgb(0 0 0 / 25%);
    display: flex;
    justify-content: space-around;
    > div:nth-child(1) {
        width: 200px;
        padding: 3px;
    }
    > div:nth-child(2) {
        width: 200px;
        padding: 3px;
    }
    > div {
        width: 100px;
    }
`
