import XLSX from 'xlsx'
import {
    IChirpstackDeviceMeasures,
    IDeviceMeasuresV2,
} from '../intefaces/measure'
import moment from 'moment'
import i18n from '../i18n'

const keyParser = (k: string) => {
    switch (k) {
        case 'CreatedAt':
            return i18n.t('Time')
        case 'time':
            return i18n.t('Time')
        case 'temperature':
            return i18n.t('Temperature') + '[°C]'
        case 'humidity':
            return i18n.t('Humidity') + '[%]'
        case 'lvoc':
            return i18n.t('TVOC') + '[ppb]'
        case 'tvoc':
            return i18n.t('TVOC') + '[ppb]'
        case 'co2':
            return i18n.t('CO2') + '[ppm]'
        case 'pm1':
            return i18n.t('PM 1.0') + '[µg/m³]'
        case 'pm2dot5':
            return i18n.t('PM 2.5') + '[µg/m³]'
        case 'pm10':
            return i18n.t('PM 10.0') + '[µg/m³]'
        case 'battery':
            return i18n.t('Battery') + '[V]'
        case 'pressure':
            return i18n.t('Pressure') + '[mb]'
        case 'formaldehyde':
            return i18n.t('Formaldehyde') + '[µg/m³]'
        case 'ullage':
            return i18n.t('Ullage')
        default:
            return i18n.t('unknown')
    }
}
export const exportDataToXlsx = (
    data: Omit<IDeviceMeasuresV2, 'id' | 'location' | 'measureBid'>[],
    ICONE: string
) => {
    var workbook = XLSX.utils.book_new()
    const res = data.map((v) =>
        Object.entries(v).reduce((acc, [k, v]) => {
            if (
                k === 'id' ||
                k === 'location' ||
                k === 'measureBid' ||
                k === 'name'
            ) {
                return acc
            }
            if (k === 'time') {
                acc[keyParser(k)] = moment.unix(v as number).format()
                return acc
            }
            acc[keyParser(k)] = v
            return acc
        }, {} as { [x: string]: any })
    )
    if (res[0]) {
        res[0][ICONE] = null
    }
    const worksheet = XLSX.utils.json_to_sheet(res as unknown[])

    XLSX.utils.book_append_sheet(workbook, worksheet, 'export')

    XLSX.writeFile(workbook, `${data?.[0]?.['name'] ?? ''}-export.xlsb`)
}

export const exportDataToXlsIoT = (
    data: IChirpstackDeviceMeasures[],
    deviceName: string,
    keys: string[]
) => {
    var workbook = XLSX.utils.book_new()
    const res = data.map((v) =>
        Object.entries(v).reduce((acc, [k, v]) => {
            if (!keys.includes(k)) {
                return acc
            }
            if (k === 'CreatedAt') {
                acc[keyParser(k)] = moment(v).format()
                return acc
            }
            acc[keyParser(k)] = v
            return acc
        }, {} as { [x: string]: any })
    )
    const worksheet = XLSX.utils.json_to_sheet(res as unknown[])

    XLSX.utils.book_append_sheet(workbook, worksheet, 'export')

    XLSX.writeFile(workbook, `${deviceName}-export.xlsb`)
}
