import React from 'react'
import styled from 'styled-components'
import {
    Green,
    HighWarning,
    LightWarning,
    MediumWarning,
    Red,
} from '../../theme/theme'
import DashLineChart from '../DashboardUnits/DashLineChart'
// import data from './mockdata/data.json'
import moment from 'moment'
import { IReportData } from '../../intefaces/measure'
// count data point number in different range of value
const analyseData = (data: { x: number; y: number }[]) => {
    const count = {
        red: 0,
        highWarning: 0,
        mediumWarning: 0,
        lightWarning: 0,
        green: 0,
    }
    let sum = 0
    let max = 0
    let maxAt = 0
    let num = 0
    const icone = {
        n0: 0,
        n1: 0,
        n2: 0,
        total: 0,
        res: 0,
    }

    data.forEach((item) => {
        // sum, avg max
        sum += item.y
        num++
        if (item.y > max) {
            max = item.y
            maxAt = item.x
        }
        // count
        if (item.y > 2500) {
            count.red++
        } else if (item.y > 1700) {
            count.highWarning++
        } else if (item.y > 1000) {
            count.mediumWarning++
        } else if (item.y > 600) {
            count.lightWarning++
        } else {
            count.green++
        }

        // icone
        if (item.y < 1000) {
            icone.n0 += 1
        } else if (item.y < 1700) {
            icone.n1 += 1
        } else {
            icone.n2 += 1
        }
        icone.total += 1
        icone.res = iconeCalculator(icone)
    })
    return { count, max, maxAt, avg: num ? sum / num : 0, icone }
}
const iconeCalculator = (target: {
    n0: number
    n1: number
    n2: number
    total: number
}) => {
    if (target.total < 50) {
        return -1
    }
    return 2.5 * Math.log2((target.n1 + 3 * target.n2) / target.total + 1)
}
const ReportBody = ({ data }: { data: IReportData }) => {
    const chartData =
        data.co2SeriesData
            ?.map((item) => {
                return {
                    x: item.time,
                    y: item.co2,
                    tooltipV: 'data',
                    type: 'co2[ppm]',
                }
            })
            ?.sort((a, b) => b.x - a.x) ?? []
    const { count, max, avg, maxAt, icone } = analyseData(chartData)

    return (
        <ReportContainer>
            <TitleWrapper>
                <TitleColumn>
                    <div>Localisation :</div>
                    <div>Marie de la Mûre</div>
                </TitleColumn>
                <TitleColumn>
                    <div>
                        Rapport Hebdomadaire CO<sub>2</sub>
                    </div>
                    <div style={{ textAlign: 'center' }}>Etat Civil</div>
                </TitleColumn>
                <TitleColumn>
                    <div>S{data.week}</div>
                </TitleColumn>
            </TitleWrapper>
            <ChartWrapper>
                <DashLineChart
                    titleHeight={60}
                    title={
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <div>
                                Evolution de la concentration en CO
                                <sub>2</sub> (ppm)
                            </div>
                            <div>Etat Civil - S44</div>
                        </div>
                    }
                    width={700}
                    height={350}
                    data={chartData}
                />
            </ChartWrapper>
            <IndicesWrapper>ICONE: {icone.res.toFixed(2)}</IndicesWrapper>
            <DetailsTitle>Période occupation</DetailsTitle>
            <DetailsWrapper>
                <DetailsColumn>
                    <DetailsRow>
                        <DetailsColumn>
                            [CO<sub>2</sub>] max:
                        </DetailsColumn>
                        <DetailsColumn>{max} ppm</DetailsColumn>
                    </DetailsRow>
                    <DetailsRow>
                        <DetailsColumn>
                            [CO<sub>2</sub>] moy:
                        </DetailsColumn>
                        <DetailsColumn>{avg.toFixed(0)} ppm</DetailsColumn>
                    </DetailsRow>
                </DetailsColumn>
                <DetailsColumn>
                    <DetailsRow>
                        {moment.unix(maxAt).format('YYYY/MM/DD HH:mm:ss')}
                    </DetailsRow>
                </DetailsColumn>
            </DetailsWrapper>
            <Alert>
                <AlertColumn>Seuil Alerte:</AlertColumn>
                <AlertColumn>0</AlertColumn>
            </Alert>
            <CO2Table>
                <CO2Row>
                    <CO2Column />
                    <CO2Column style={{ textAlign: 'center' }}>
                        Plages
                    </CO2Column>
                    <CO2Column />
                    <CO2Column>Nb Heures</CO2Column>
                </CO2Row>
                <CO2Row>
                    <CO2Column
                        style={{
                            textAlign: 'center',
                            backgroundColor: Green,
                        }}
                    >
                        1
                    </CO2Column>
                    <CO2Column style={{ textAlign: 'center' }}>
                        400-600
                    </CO2Column>
                    <CO2Column>Excellent</CO2Column>
                    <CO2Column style={{ textAlign: 'right' }}>
                        {count.green}
                    </CO2Column>
                </CO2Row>
                <CO2Row>
                    <CO2Column
                        style={{
                            textAlign: 'center',
                            backgroundColor: LightWarning,
                        }}
                    >
                        2
                    </CO2Column>
                    <CO2Column style={{ textAlign: 'center' }}>
                        600-1000
                    </CO2Column>
                    <CO2Column>Bon</CO2Column>
                    <CO2Column style={{ textAlign: 'right' }}>
                        {count.lightWarning}
                    </CO2Column>
                </CO2Row>
                <CO2Row>
                    <CO2Column
                        style={{
                            textAlign: 'center',
                            backgroundColor: MediumWarning,
                        }}
                    >
                        3
                    </CO2Column>
                    <CO2Column style={{ textAlign: 'center' }}>
                        1000-1700
                    </CO2Column>
                    <CO2Column>Moyen</CO2Column>
                    <CO2Column style={{ textAlign: 'right' }}>
                        {count.mediumWarning}
                    </CO2Column>
                </CO2Row>
                <CO2Row>
                    <CO2Column
                        style={{
                            textAlign: 'center',
                            backgroundColor: HighWarning,
                        }}
                    >
                        4
                    </CO2Column>
                    <CO2Column style={{ textAlign: 'center' }}>
                        1700-2500
                    </CO2Column>
                    <CO2Column>Mauvais</CO2Column>
                    <CO2Column style={{ textAlign: 'right' }}>
                        {count.highWarning}
                    </CO2Column>
                </CO2Row>
                <CO2Row>
                    <CO2Column
                        style={{
                            textAlign: 'center',
                            backgroundColor: Red,
                        }}
                    >
                        5
                    </CO2Column>
                    <CO2Column style={{ textAlign: 'center' }}>
                        2500-5000
                    </CO2Column>
                    <CO2Column>Critique</CO2Column>
                    <CO2Column style={{ textAlign: 'right' }}>
                        {count.red}
                    </CO2Column>
                </CO2Row>
            </CO2Table>
            <Observation>Observations / Commentaires:</Observation>
            <ObservationDetails></ObservationDetails>
        </ReportContainer>
    )
}
export default ReportBody
const ReportContainer = styled.div`
    min-width: 800px;
    position: absolute;
    left: calc(50vw - 400px);
`
const TitleWrapper = styled.div`
    margin: 0px 20px;

    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`
const TitleColumn = styled.div`
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    > div {
        height: 40px;
    }
`
const ChartWrapper = styled.div`
    width: 100%;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
const IndicesWrapper = styled.div`
    font-size: 1.5rem;
    margin: 10px 50px;
`
const DetailsWrapper = styled.div`
    display: flex;
    align-content: center;
    justify-content: space-around;
`
const DetailsTitle = styled.h2`
    text-align: center;
`

const DetailsRow = styled.div`
    display: flex;
    justify-content: space-between;
    width: 300px;
`
const DetailsColumn = styled.div``
const Alert = styled.div`
    display: flex;
    font-size: 1.5rem;
    margin: 10px 50px;
`
const AlertColumn = styled.div`
    margin-right: 20px;
`

const CO2Table = styled.div`
    display: flex;
    flex-direction: column;
`
const CO2Row = styled.div`
    display: flex;
    justify-content: space-evenly;
`
const CO2Column = styled.div`
    width: 100px;
`

const Observation = styled.div`
    display: flex;
    font-size: 1.5rem;
    margin: 10px 50px;
`

const ObservationDetails = styled.div`
    display: flex;
    height: 300px;
    margin: 0px 20px;
    border: 1px solid black;
`
