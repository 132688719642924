import React from 'react'
import { Chart, Line } from 'bizcharts'
import { IHistory } from './Interface'
const SimpleChart = ({ data }: { data: IHistory[] }) => {
    return (
        <Chart
            scale={{ value: { min: 0 } }}
            autoFit
            width={50}
            height={30}
            pure
            data={data}
        >
            <Line
                animate={{ appear: false }}
                shape="smooth"
                position="ts*temperature"
                color="l (270) 0:rgba(80 103 227 / 90%) .5:rgba(55 230 79 / 90%) 1:rgba(255 85 0 / 85%)"
            />
        </Chart>
    )
}

export default SimpleChart
