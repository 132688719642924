export const dropShaw = (elevation: number) => ({
    boxShadow: `
    0 0 1px rgba(0,0,0,.12),
    0 ${elevation / 4}px ${elevation}px rgba(0,0,0,.08),   
    0 ${elevation / 2}px ${elevation * 2}px -${elevation / 2}px rgba(0,0,0,.12)
    `,
})

export const Gray = '#C6C6C6'
export const Green = '#4caf50'
export const LightWarning = '#8bc34a'
export const MediumWarning = '#fdd835'
export const HighWarning = '#ff9800'
export const Alert = '#ff5722'
export const Red = '#dd2c00'
export const DefaultColor = '#5b8ff9'
export const gradientOrange =
    'linear-gradient(to top, rgb(255 234 172 / 90%), #e3d350)'
export const gradientBlue =
    'linear-gradient(to top, rgb(221 243 223 / 38%), #3defd0)'
export const gradientGreen =
    'linear-gradient(to top, rgb(172 255 172 / 90%), #50e37f)'
export const gradientRed =
    'linear-gradient(to top,rgb(237 161 161 / 90%),#fd2121)'
export const sideBarWidth = 250
export const headerHeight = 60
