import styled from 'styled-components'
import React, { useRef } from 'react'
import Plan from '../../../assets/houche2.png'
import { SensorWithData } from './SensorWithData'
import { dropShaw } from '../../../theme/theme'
import { IChirpstackDevice } from './Interface'

export const PlanWithSensor = ({
    planName,
    planId,
    pickedSensor,
    setPickedSensor,
    sensors,
    updateSensorIndoorPosition,
    onContextMenu,
}: {
    planName: string
    planId: number
    pickedSensor: number
    setPickedSensor: Function
    sensors: IChirpstackDevice[]
    updateSensorIndoorPosition: Function
    onContextMenu: Function
}) => {
    const wrapperRef = useRef<HTMLImageElement>(null)
    return (
        <>
            <Wrapper
                ref={wrapperRef}
                onContextMenu={(e) => {
                    e.preventDefault()
                }}
            >
                <Img
                    id={'plan'}
                    src={Plan}
                    onClick={(ev) => {
                        updateSensorIndoorPosition([
                            wrapperRef.current
                                ? (ev.clientX -
                                      (wrapperRef.current.offsetLeft ?? 0)) /
                                  wrapperRef.current.clientWidth
                                : 0,
                            wrapperRef.current
                                ? (ev.clientY -
                                      (wrapperRef?.current?.offsetTop ?? 0)) /
                                  wrapperRef.current.clientHeight
                                : 0,
                        ])
                    }}
                    onContextMenu={() => {
                        onContextMenu()
                    }}
                    alt={'indoor plan'}
                />
                {sensors
                    ?.filter((d) => d.planId === planId)
                    ?.map(({ ID, indoorX, indoorY, lastMeasure }) => (
                        <SensorWithData
                            key={ID}
                            indoorPosition={[indoorX, indoorY]}
                            measure={lastMeasure}
                            picked={ID === pickedSensor}
                            onContextMenu={() => {
                                setPickedSensor(ID)
                            }}
                        />
                    ))}
            </Wrapper>
        </>
    )
}
const Wrapper = styled.div`
    position: relative;
    width: 100%;
    height: calc(100vh - 60px);
    ${dropShaw(10)}
`
const Img = styled.img`
    width: 100%;
    height: 100%;
`
