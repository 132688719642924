import React, { useState } from 'react'
import styled from 'styled-components'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { dropShaw } from '../theme/theme'
import LogoPng from '../assets/logo.png'
import MenuSvg from '../assets/menu.svg'
import DashboardSvg from '../assets/business-report.svg'
import IoT from '../assets/internet-of-things.png'
import MapSvg from '../assets/map.svg'
import SmartHomeSvg from '../assets/smart-home.svg'
import ConfigSvg from '../assets/config.svg'
import { AmplifySignOut } from '@aws-amplify/ui-react'
import { useTranslation } from 'react-i18next'

const Header: React.FunctionComponent<{
    children: React.ReactChild | React.ReactChildren
    signedIn: boolean
    onSignOut: Function
    groups: string[]
}> = ({ children, signedIn, onSignOut, groups }) => {
    const [menuOpen, setMenuOpen] = useState(false)
    const history = useHistory()
    const { url } = useRouteMatch()
    const { t, i18n } = useTranslation()
    const languageGetter = () => {
        if (i18n.language === 'fr') {
            return 'en'
        }
        return 'fr'
    }
    const targetLanguage = languageGetter()

    const isAdmin = () => groups.some((g) => g === 'admin')

    return (
        <Container>
            <Content maxWidth={2560}>
                <a href={`/${i18n.language}`}>
                    <Logo />
                </a>
                {children}
            </Content>
            <Language
                onClick={() => {
                    window.location.replace(
                        history.location.pathname.replace(
                            `/${i18n.language}`,
                            `/${targetLanguage}`
                        )
                    )
                }}
            >
                {targetLanguage}
            </Language>
            {signedIn ? (
                <>
                    <SignOutWrapper
                        large={i18n.language === 'fr'}
                        onClick={() => onSignOut()}
                    >
                        <AmplifySignOut />
                    </SignOutWrapper>
                    <Menu
                        onClick={() => {
                            setMenuOpen(!menuOpen)
                        }}
                    >
                        <img width={30} src={MenuSvg} alt={'menu'} />
                    </Menu>
                </>
            ) : (
                <></>
            )}

            {menuOpen ? (
                <>
                    <MenuItems>
                        <MenuItem
                            onClick={() => {
                                history.push(`${url}/dashboard`)
                                setMenuOpen(false)
                            }}
                        >
                            <SvgWrapper>
                                <Img src={DashboardSvg} />
                            </SvgWrapper>
                            <Subtitle>{t('Dashboard')}</Subtitle>
                        </MenuItem>

                        <MenuItem
                            onClick={() => {
                                history.push(`${url}`)
                                setMenuOpen(false)
                            }}
                        >
                            <SvgWrapper>
                                <Img src={MapSvg} />
                            </SvgWrapper>
                            <Subtitle>{t('Map')}</Subtitle>
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                history.push(`${url}/indoor`)
                                setMenuOpen(false)
                            }}
                        >
                            <SvgWrapper>
                                <Img src={SmartHomeSvg} />
                            </SvgWrapper>
                            <Subtitle>{t('H/T')}</Subtitle>
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                history.push(`${url}/dashboard-indoor`)
                                setMenuOpen(false)
                            }}
                        >
                            <SvgWrapper>
                                <Img src={IoT} />
                            </SvgWrapper>
                            <Subtitle>{t('IoT')}</Subtitle>
                        </MenuItem>
                        {isAdmin() ? (
                            <MenuItem
                                onClick={() => {
                                    history.push(`${url}/admin`)
                                    setMenuOpen(false)
                                }}
                            >
                                <SvgWrapper>
                                    <Img src={ConfigSvg} />
                                </SvgWrapper>
                                <Subtitle>{t('Admin')}</Subtitle>
                            </MenuItem>
                        ) : (
                            <></>
                        )}
                    </MenuItems>
                </>
            ) : (
                <></>
            )}
        </Container>
    )
}
export default Header

const Container = styled.div`
    height: 60px;
    position: relative;
    z-index: 100;
    ${dropShaw(10)};
    background-color: white;
`

const Content = styled.div<{ maxWidth: number }>`
    display: flex;
    align-items: center;
    max-width: ${({ maxWidth }) => maxWidth}px;
    padding: 0 60px;
    margin: 0 auto;

    > h1 {
        font-weight: lighter;
        font-size: 27px;
        margin: 0;
        text-transform: uppercase;
    }
    > a {
        text-decoration: none;
    }
`

const Logo = styled.div`
    margin: 5px 30px 5px 0;
    height: 50px;
    width: 111px;
    background-image: url(${LogoPng});
`
const Language = styled.div<{ large?: boolean }>`
    position: absolute;
    top: 20px;
    right: 300px;
    cursor: pointer;
    text-transform: uppercase;
`
const SignOutWrapper = styled.div<{ large?: boolean }>`
    position: absolute;
    display: flex;
    top: 12px;
    right: 110px;
    box-shadow: 0px 1px 5px rgb(0 0 0 / 25%);
    height: 36px;
    border-radius: 4px;
    border: 1px solid #b4a3a3;
    width: ${({ large }) => (large ? '130' : '90')}px;
    justify-content: center;
    align-items: center;
`
const Menu = styled.div`
    position: absolute;
    right: 0%;
    top: 0%;
    bottom: 0%;

    background: #ffffff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08),
        0px 7px 20px rgba(33, 24, 67, 0.14);
    width: 60px;
    display: flex;
    padding: 18px;
    justify-content: center;
    cursor: pointer;
`

const MenuItems = styled.div`
    position: absolute;
    right: 0%;
    top: 60px;

    background: #ffffff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08),
        0px 7px 20px rgba(33, 24, 67, 0.14);
    min-width: 96px;
    max-width: 270px;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: baseline;
    display: flex;
`
const SvgWrapper = styled.div`
    width: 60px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`
const Subtitle = styled.div`
    font-size: 10px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

const MenuItem = styled.div`
    width: 60px;
    height: auto;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    margin: 20px 10px 10px 20px;
    transition: background-color 0.5s ease;
    &:hover {
        background-color: #4b4be814;
        > ${Subtitle} {
            overflow: visible;
            white-space: inherit;
        }
    }
`
const Img = styled.img`
    width: 30px;
`
