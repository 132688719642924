import {
    EuiModal,
    EuiModalBody,
    EuiModalHeader,
    EuiModalHeaderTitle,
} from '@elastic/eui'
import React from 'react'

const Modal = ({
    title,
    closeModal,
    children,
}: {
    title: string
    closeModal: (
        event?:
            | React.KeyboardEvent<HTMLDivElement>
            | React.MouseEvent<HTMLButtonElement>
    ) => void
    children: Function
}) => {
    return (
        <EuiModal onClose={closeModal}>
            <EuiModalHeader>
                <EuiModalHeaderTitle>
                    <h1>{title}</h1>
                </EuiModalHeaderTitle>
            </EuiModalHeader>
            <EuiModalBody>{children(closeModal)}</EuiModalBody>
        </EuiModal>
    )
}

export default Modal
