import React, { ReactNode } from 'react'
import { Axis, Chart, Interaction, Legend, Line, Tooltip } from 'bizcharts'
import { ListItem } from 'bizcharts/lib/plots/core/dependents'
import moment from 'moment'
import styled from 'styled-components'
import { dropShaw } from '../../theme/theme'
import { ILine } from '../../intefaces/measure'
import { AdjustOption } from '@antv/g2/lib/interface'

const DashLineChart = ({
    title,
    height,
    width,
    data,
    titleHeight = 30,
    adjust = [],
}: {
    title: string | ReactNode | ReactNode[]
    height: number
    width: number
    data: ILine[]
    titleHeight?: number
    adjust?: AdjustOption[]
}) => (
    <ChartWrapper height={height - titleHeight}>
        <Title titleHeight={titleHeight}>{title}</Title>
        <Chart
            data={data}
            width={width - titleHeight}
            autoFit
            height={height - titleHeight * 2}
        >
            <Axis
                name="x"
                label={{
                    formatter: (
                        text: string,
                        item: ListItem,
                        index: number
                    ) => {
                        return moment
                            .unix(parseInt(item?.id ?? ''))
                            .format('MM/DD HH:mm:ss')
                    },
                }}
            />
            <Line
                animate={{ appear: false }}
                position="x*y"
                color="type"
                adjust={adjust}
            />
            <Tooltip title={'tooltipV'} shared showCrosshairs />
            <Legend position="right-top" />
            <Interaction type="brush" />
        </Chart>
    </ChartWrapper>
)

export default DashLineChart

const ChartWrapper = styled.div<{ height: number }>`
    ${dropShaw(10)};
    margin: 5px;
    padding: 10px;
    height: ${({ height }) => height}px;
    box-sizing: content-box;
`

const Title = styled.div<{ titleHeight: number }>`
    height: ${(props) => props.titleHeight}px;
    line-height: 30px;
    text-align: left;
    font-weight: 500;
    font-size: 18px;
`
